import React, {useState, useEffect} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {
  setDoc,
  doc,
  collection,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useAuth} from "../../../services/use-auth";
import StyledButton from "../../../assets/buttons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {MenuItem} from "@mui/material";
import {
  Text,
  ComponentInnerWrapper,
  ComponentOutterWrapper,
  FooterWrapper,
  HeaderWrapper,
  Table,
  TableHeader,
  LoadingLogoComp,
  ModalBox,
  ModalInner,
  TableHeaderDiv,
  EditWrapper,
  TitleText,
  LargeTitle,
} from "./modify_resources_css";
import {ConfirmAction} from "../../../assets/controls";

import PersonAdd from "@mui/icons-material/PersonAdd";

const AreasWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const AddOrChangeInstructor = (props) => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    empStatus: "partTime",
    onlineCert: false,
    areas: "",
  });
  const [entryErrors, setEntryErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  useEffect(() => {
    if (props.dataIn && props.dataIn.email.length > 0 && values.email === "") {
      setValues({
        firstName: props.dataIn.firstName,
        lastName: props.dataIn.lastName,
        email: props.dataIn.email,
        empStatus: props.dataIn.fullTime ? "fullTime" : "partTime",
        onlineCert: props.dataIn.onlineCert || false,
        areas: props.dataIn.areas.join(", "),
      });
    }
  }, [props.dataIn, values.email]);

  const handleChange = (event) => {
    if (event.target.name === "onlineCert") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    } else if (event.target.name === "areas") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.toUpperCase(),
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const validatefirstName = () => {
    if (values.firstName === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, firstName: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        firstName: "You must enter a first name",
      }));
    } else {
      if (values.firstName.includes(" ")) {
        setEntryErrors((entryErrors) => ({...entryErrors, firstName: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          firstName: "You must enter only one name with no spaces",
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, firstName: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          firstName: null,
        }));
      }
    }
  };

  const validatelastName = () => {
    if (values.lastName === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, lastName: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        lastName: "You must enter a last name",
      }));
    } else {
      if (values.lastName.includes(" ")) {
        setEntryErrors((entryErrors) => ({...entryErrors, lastName: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          lastName: "Please hyphenate multiple last names",
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, lastName: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          lastName: null,
        }));
      }
    }
  };

  const validateEmail = () => {
    if (values.email === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        email: "You must enter a email",
      }));
    } else {
      const containsAtAndPeriod =
        values.email.includes("@") && values.email.includes(".");
      if (!containsAtAndPeriod) {
        setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: "You must enter a valid email address",
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, email: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: null,
        }));
      }
    }
  };

  const validateAreas = () => {
    if (values.areas === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, areas: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        areas: "You must enter areas",
      }));
    } else {
      const areasArr = values.areas
        .replace(/\s+/g, "")
        .toUpperCase()
        .split(",")
        .filter((item) => item !== "");
      console.log(areasArr);
      const hasFourLetters = areasArr.every(
        (areaCode) => areaCode.length === 4
      );
      const isOnlyLetters = areasArr.every((areaCode) =>
        /^[A-Z]+$/.test(areaCode)
      );
      if (!hasFourLetters) {
        setEntryErrors((entryErrors) => ({...entryErrors, areas: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          areas: "Each area must have 4 letters, separated by a comma",
        }));
      } else {
        if (!isOnlyLetters) {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            areas: true,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            areas: "Areas can only have letters",
          }));
        } else {
          setEntryErrors((entryErrors) => ({...entryErrors, areas: false}));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            areas: null,
          }));
        }
      }
    }
  };

  const invalid =
    values.email === "" ||
    entryErrors.firstName ||
    entryErrors.lastName ||
    entryErrors.email ||
    entryErrors.areas;

  const employmentOptions = [
    {label: "Part Time", value: "partTime"},
    {label: "Full Time", value: "fullTime"},
  ];

  const addInstructor = async (values) => {
    console.log("Adding: ", values);
    props.closeModal();
    const areasArr = values.areas.replace(/\s/g, "").split(",");
    console.log("Areas: ", areasArr);
    const docRef = doc(
      props.authHook.db,
      "institutions",
      props.institution.docId,
      "instructors",
      values.email
    );
    const instructorData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      onlineCert: values.onlineCert,
      areas: areasArr,
      fullTime: values.empStatus === "fullTime",
      partTime: values.empStatus === "partTime",
      fullName: values.firstName.concat(" ").concat(values.lastName),
    };
    await setDoc(docRef, instructorData);
  };

  return (
    <React.Fragment>
      <TitleText>Add or Edit Faculty</TitleText>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="First Name"
          name="firstName"
          variant="outlined"
          color="secondary"
          value={values["firstName"]}
          onChange={handleChange}
          onBlur={() => validatefirstName()}
          error={entryErrors.firstName}
          helperText={entryFeedback.firstName}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Last Name"
          name="lastName"
          variant="outlined"
          color="secondary"
          value={values["lastName"]}
          onChange={handleChange}
          onBlur={() => validatelastName()}
          error={entryErrors.lastName}
          helperText={entryFeedback.lastName}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Email Address"
          name="email"
          variant="outlined"
          color="secondary"
          value={values["email"]}
          onChange={handleChange}
          onBlur={() => validateEmail()}
          error={entryErrors.email}
          helperText={
            props.dataIn
              ? "Email addresses cannot be changed for existing faculty"
              : entryFeedback.email
          }
          disabled={props.dataIn}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Areas of Instruction"
          name="areas"
          variant="outlined"
          color="secondary"
          value={values["areas"]}
          onChange={handleChange}
          onBlur={() => validateAreas()}
          error={entryErrors.areas}
          helperText={entryFeedback.areas}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Employment Status"
          name="empStatus"
          color="secondary"
          select
          value={values["empStatus"]}
          onChange={handleChange}>
          {employmentOptions.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Switch
              name={"onlineCert"}
              checked={values["onlineCert"]}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Online Certified"
        />
        <StyledButton
          disabled={invalid}
          primary
          callback={() => addInstructor(values)}
          width="300px"
          fontSize="26px">
          {props.buttonText} <PersonAdd style={{paddingLeft: "12px"}} />
        </StyledButton>
      </Box>
    </React.Fragment>
  );
};

const Instructors = (props) => {
  const [instructors, setInstructors] = useState([]);
  const [instructorDataToEdit, setInstructorDataToEdit] = useState({});
  const [instructorToDelete, setInstructorToDelete] = useState(undefined);
  const [numberOfInstructorsToDisplay, setNumberOfInstructorsToDisplay] =
    useState(10);
  const [refineInstructors, setRefineInstructors] = useState("");
  const [filteredInstructors, setFilteredInstructors] = useState([]);

  const [openAddInstructor, setOpenAddInstructor] = useState(false);
  const handleOpenAddInstructor = () => setOpenAddInstructor(true);
  const handleCloseAddInstructor = () => setOpenAddInstructor(false);

  const [openEditInstructor, setOpenEditInstructor] = useState(false);
  const handleOpenEditInstructor = () => setOpenEditInstructor(true);
  const handleCloseEditInstructor = () => setOpenEditInstructor(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const authHook = useAuth();

  useEffect(() => {
    if (props.institution.docId) {
      const collectionRef = collection(
        authHook.db,
        "institutions",
        props.institution.docId,
        "instructors"
      );

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const newInstructors = [];
        const removedInstructors = [];
        const changedInstructors = [];
        snapshot.docChanges().forEach((change) => {
          console.log(change);
          if (change.type === "added") {
            console.log("New instructor: ", change.doc.data());
            if (
              !instructors.some(
                (instructor) => change.doc.data().email === instructor.email
              )
            ) {
              newInstructors.push(change.doc.data());
            }
          }
          if (change.type === "modified") {
            console.log("Modified instructor: ", change.doc.data());
            changedInstructors.push(change.doc.data());
          }
          if (change.type === "removed") {
            console.log("Removed instructor: ", change.doc.data().email);
            removedInstructors.push(change.doc.data());
          }
        });
        setInstructors((instructors) => {
          instructors.filter((instructor) =>
            removedInstructors.some(
              (listOfRemoved) => listOfRemoved.email !== instructor.email
            )
          );
          return [
            ...instructors.filter(
              (instructor) =>
                (removedInstructors.length === 0 &&
                  changedInstructors.length === 0) ||
                removedInstructors.some(
                  (listOfRemoved) => listOfRemoved.email !== instructor.email
                ) ||
                changedInstructors.some(
                  (listOfAltered) => listOfAltered.email !== instructor.email
                )
            ),
            ...newInstructors,
            ...changedInstructors,
          ];
        });
      });

      return () => {
        unsubscribe();
      };
    }
  }, [authHook.db, props.institution]);

  const handleChange = (event) => {
    setRefineInstructors(event.target.value);
  };

  useEffect(() => {
    if (refineInstructors) {
      let filteredTemp = instructors.filter(
        (instructor) =>
          instructor.lastName
            .toLowerCase()
            .includes(refineInstructors.toLowerCase()) ||
          instructor.firstName
            .toLowerCase()
            .includes(refineInstructors.toLowerCase())
      );
      setFilteredInstructors(
        filteredTemp.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
      );
    } else {
      setFilteredInstructors(
        instructors.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
      );
    }
  }, [instructors, refineInstructors]);

  const removeInstructor = (dataIn) => {
    console.log(`Delete ${dataIn.id}`);
    deleteDoc(
      doc(
        authHook.db,
        "institutions",
        props.institution.docId,
        "instructors",
        dataIn.id
      )
    );
  };

  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  return (
    <ComponentOutterWrapper>
      <ComponentInnerWrapper>
        <LargeTitle>Instructors</LargeTitle>
        <HeaderWrapper>
          <ThemeProvider theme={theme}>
            {/* <AddResourceButton
              icon="person"
              callback={() => handleOpenAddInstructor()}>
              Add Faculty
            </AddResourceButton> */}
            <StyledButton
              bright
              callback={() => handleOpenAddInstructor()}
              fontSize={"1.25rem"}
              height={"2.8rem"}
              width={"14rem"}>
              Add Faculty <PersonAdd style={{paddingLeft: "12px"}} />
            </StyledButton>
            <Box component="form" sx={{pl: 5}} noValidate autoComplete="off">
              <TextField
                sx={{width: "250px", fontSize: "22px"}}
                label="Search Instructors"
                name="areas"
                variant="outlined"
                color="secondary"
                value={refineInstructors}
                onChange={handleChange}
              />
            </Box>
          </ThemeProvider>
        </HeaderWrapper>
        <Table>
          <TableHeaderDiv>
            <TableHeader>Instructor Name</TableHeader>
          </TableHeaderDiv>
          <TableHeaderDiv>
            <TableHeader>Area(s)</TableHeader>
          </TableHeaderDiv>
          <TableHeaderDiv>
            <TableHeader>Employment</TableHeader>
          </TableHeaderDiv>
          <TableHeaderDiv />
          {filteredInstructors.length > 0 &&
            filteredInstructors.map(
              (instructor, index) =>
                index <= numberOfInstructorsToDisplay && (
                  <React.Fragment key={instructor.email}>
                    <div>
                      <Text>{`${instructor.firstName} ${instructor.lastName}`}</Text>
                    </div>
                    <div>
                      <AreasWrapper>
                        {instructor.areas.map((area, index) => {
                          if (
                            instructor.areas.length > 1 &&
                            index < instructor.areas.length - 1
                          ) {
                            return <Text key={area}>{area}, </Text>;
                          } else if (
                            instructor.areas.length > 1 &&
                            index === instructor.areas.length - 1
                          ) {
                            return <Text key={area}>{area}</Text>;
                          } else {
                            return <Text key={area}>{area}</Text>;
                          }
                        })}
                      </AreasWrapper>
                    </div>
                    <div>
                      {instructor.fullTime ? (
                        <Text>Full Time</Text>
                      ) : (
                        <Text>Part Time</Text>
                      )}
                    </div>

                    <EditWrapper>
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          paddingRight: "2vw",
                          color: "white",
                        }}
                        onClick={() => {
                          console.log(`Edit ${instructor.email}`);
                          setInstructorDataToEdit(instructor);
                          handleOpenEditInstructor();
                        }}
                      />
                      <DeleteForeverIcon
                        style={{cursor: "pointer", color: "white"}}
                        onClick={() => {
                          handleOpenDelete();
                          setInstructorToDelete({
                            id: instructor.email,
                            label: `${instructor.firstName} ${instructor.lastName}`,
                          });
                        }}
                      />
                    </EditWrapper>
                  </React.Fragment>
                )
            )}
        </Table>
        {instructors.length === 0 && <LoadingLogoComp />}
        <Modal open={openAddInstructor} onClose={handleCloseAddInstructor}>
          <ModalBox>
            <ModalInner>
              <AddOrChangeInstructor
                buttonText={"Add Instructor"}
                authHook={authHook}
                closeModal={handleCloseAddInstructor}
                institution={props.institution}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <Modal open={openEditInstructor} onClose={handleCloseEditInstructor}>
          <ModalBox>
            <ModalInner>
              {instructorDataToEdit && (
                <AddOrChangeInstructor
                  buttonText={"Save Changes"}
                  authHook={authHook}
                  closeModal={handleCloseEditInstructor}
                  institution={props.institution}
                  dataIn={instructorDataToEdit}
                />
              )}
            </ModalInner>
          </ModalBox>
        </Modal>
        <ConfirmAction
          dataForAction={instructorToDelete}
          callback={(data) => removeInstructor(data)}
          openState={openDelete}
          handleCloseState={() => handleCloseDelete()}>
          Delete
        </ConfirmAction>
        <FooterWrapper>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"18rem"}
            callback={() =>
              setNumberOfInstructorsToDisplay(
                (numberOfInstructorsToDisplay) =>
                  numberOfInstructorsToDisplay + 20
              )
            }>
            Show More
          </StyledButton>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"18rem"}
            callback={() => props.setResourceComp("none")}>
            Done With Instructors
          </StyledButton>
        </FooterWrapper>
      </ComponentInnerWrapper>
    </ComponentOutterWrapper>
  );
};

export default Instructors;
