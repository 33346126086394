import React, {useState, useEffect} from "react";
import SelectNumberOfSessions from "./select_number_of_sessions";
import WeekComp from "./grid_elements/weekComponent";
import {doc, getDoc, addDoc, collection} from "firebase/firestore";
import {useAuth} from "../../../services/use-auth";
import {getAvailability} from "./availabilityCompiler/getAvailability";
import {refineResources} from "./availabilityCompiler/refineResources";
import {
  HeaderWrapper,
  BodyWrapper,
  ModalBox,
  ModalInner,
} from "./schedule_course_styles";
import FinalizeAddClass from "./finalizeAddClass";
import Modal from "@mui/material/Modal";
import StyledButton from "../../../assets/buttons";

const SelectSessionsFlow = (props) => {
  const [lectureTimeSpan, setLectureTimeSpan] = useState(undefined);
  const [labTimeSpan, setLabTimeSpan] = useState(undefined);
  const [numberOfMeetings, setNumberOfMeetings] = useState(() => {
    return {
      lecture: props.course.lheLecture,
      lab: props.course.hoursLab / 3,
    };
  });
  const [confirmNumberOfMeetings, setConfirmNumberOfMeetings] = useState(false);
  const [selecting, setSelecting] = useState(() => {
    if (props.course.hoursLab) {
      return "lab";
    } else {
      return "lecture";
    }
  });
  const [allSessionsSelected, setAllSessionsSelected] = useState(false);

  const [resourcesAvailableRaw, setResourcesAvailableRaw] = useState(undefined);
  const [resourcesProcessed, setResourcesProcessed] = useState(undefined);
  const [resourcesProcessedWithHover, setResourcesProcessedWithHover] =
    useState(undefined);
  const [classTimeHovering, setClassTimeHovering] = useState(undefined);

  const [showLoading, setShowLoading] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const hasLab = props.course.hoursLab > 0;

  const authHook = useAuth();

  useEffect(() => {
    //Get available resources from the catalog => parse => apply to WeekComp
    const getResources = async () => {
      //These should both be true anyways before this component renders, but good to check
      if (
        props.semesterCat &&
        props.course &&
        props.institution &&
        props.institution.docId
      ) {
        try {
          console.log(props.institution.docId);
          console.log(props.semesterCat.id);
          console.log(props.course.area);
          const resourcesCatalogRef = doc(
            authHook.db,
            "institutions",
            props.institution.docId,
            "catalogs",
            props.semesterCat.id,
            "resourceAvailabilityByArea",
            props.course.area
          );
          const resourcesSnap = await getDoc(resourcesCatalogRef);
          const resourcesAvail = {
            instructors: resourcesSnap.data().instructorsArr,
            rooms: resourcesSnap.data().roomsArr,
          };
          setResourcesAvailableRaw(resourcesAvail);
          if (
            resourcesAvail.instructors.length > 0 &&
            resourcesAvail.rooms.length > 0
          ) {
            // Determine default number of days & times
            // this needs to take into account the length of time current for the lecture or lab you're trying to schedule, since this matters for the windows available.
            // a class cannot change rooms or instructors in the middle of a session, though an overlapping avilailability window would portend this possibility
            const spanNeeded = selecting.lab ? labTimeSpan : lectureTimeSpan;
            const labRequirement =
              selecting.lab && props.course.mustScheduleInLab
                ? props.course.area
                : false;
            const availabilityCompiled = getAvailability({
              resources: resourcesAvail,
              spanNeeded: spanNeeded,
              requirements: labRequirement,
              selectedOptions: {classSpanTimes: props.spanTimes},
              setShowLoading: setShowLoading,
              step: "primary",
            });
            setResourcesProcessed(availabilityCompiled);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    getResources();
  }, [
    authHook.db,
    labTimeSpan,
    lectureTimeSpan,
    props,
    selecting.lab,
    props.spanTimes,
  ]);

  useEffect(() => {
    if (
      props.semesterCat &&
      props.course &&
      classTimeHovering &&
      resourcesAvailableRaw
    ) {
      const spanNeeded = selecting.lab ? labTimeSpan : lectureTimeSpan;
      const labRequirement =
        selecting.lab && props.course.mustScheduleInLab
          ? props.course.area
          : false;
      setResourcesProcessedWithHover(
        refineResources({
          resources: resourcesAvailableRaw,
          spanNeeded: spanNeeded,
          requirements: labRequirement,
          selectedOptions: {classSpanTimes: props.spanTimes},
          potentialLectureTime: classTimeHovering,
        })
      );
    }
  }, [
    classTimeHovering,
    labTimeSpan,
    lectureTimeSpan,
    props.course,
    props.semesterCat,
    resourcesAvailableRaw,
    selecting.lab,
    props.spanTimes,
  ]);

  //useEffect to check if all labs or all lectures have been selected yet, and adjust accordingly
  useEffect(() => {
    const lectures = props.spanTimes.filter(
      (span) => span.classMeetingType === "lecture"
    );
    const labs = props.spanTimes.filter(
      (span) => span.classMeetingType === "lab"
    );
    if (
      numberOfMeetings.lecture === lectures.length &&
      numberOfMeetings.lab === labs.length
    ) {
      //Both lectures and labs have all been scheduled
      setAllSessionsSelected(true);
    } else if (selecting === "lab" && numberOfMeetings.lab === labs.length) {
      //Labs have all been scheduled, switch to lectures
      setSelecting("lecture");
    } else if (
      selecting === "lecture" &&
      numberOfMeetings.lecture === lectures.length
    ) {
      //Lectures have all been scheduled, switch to labs
      setSelecting("lab");
    }
  }, [
    numberOfMeetings.lab,
    numberOfMeetings.lecture,
    selecting,
    props.spanTimes,
  ]);

  const setAClassTimeRange = (lectureRangeObject) => {
    lectureRangeObject["classMeetingType"] = selecting;
    if (!allSessionsSelected) {
      props.setSpanTimes((lectureSpanTimes) => [
        ...lectureSpanTimes,
        lectureRangeObject,
      ]);
    }
  };

  const timeSpan = selecting === "lab" ? labTimeSpan : lectureTimeSpan;

  const registerClass = async (inputs) => {
    const crn = Math.round(Math.random() * (99999 - 10000) + 10000).toString();
    const classRequestObj = {
      CRN: crn,
      course: props.course,
      lectures: props.spanTimes,
      instructor: inputs.instructor,
      classroom: inputs.classroom,
      requestorID: props.authUser.uid,
    };
    console.log(classRequestObj);

    await addDoc(
      collection(
        authHook.db,
        "institutions",
        props.institution.docId,
        "catalogs",
        props.semesterCat.id,
        "classes",
        crn,
        "requests"
      ),
      classRequestObj,
      {merge: true}
    );
  };

  return (
    <React.Fragment>
      <HeaderWrapper>
        {!confirmNumberOfMeetings && (
          <SelectNumberOfSessions
            course={props.course}
            setLectureTimeSpan={setLectureTimeSpan}
            setLabTimeSpan={setLabTimeSpan}
            lectureTimeSpan={lectureTimeSpan}
            labTimeSpan={labTimeSpan}
            numberOfMeetings={numberOfMeetings}
            setNumberOfMeetings={setNumberOfMeetings}
            setConfirmNumberOfMeetings={setConfirmNumberOfMeetings}
          />
        )}
      </HeaderWrapper>
      <BodyWrapper>
        {lectureTimeSpan !== undefined &&
          (!hasLab || labTimeSpan !== undefined) &&
          confirmNumberOfMeetings && (
            <React.Fragment>
              <WeekComp
                clickedATime={setAClassTimeRange}
                spanTime={timeSpan}
                selectedLectures={props.spanTimes}
                disableSelectTime={setAllSessionsSelected}
                availResourcesCompiled={resourcesProcessed}
                refinedCompiledAvail={resourcesProcessedWithHover}
                setClassTimeHovering={(input) => setClassTimeHovering(input)}
              />
              <StyledButton
                bright
                disabled={!allSessionsSelected}
                callback={() => handleOpenModal(true)}>
                Lock In Times
              </StyledButton>
            </React.Fragment>
          )}
        <Modal open={openModal} onClose={handleCloseModal}>
          <ModalBox>
            <ModalInner>
              <FinalizeAddClass
                registerClass={registerClass}
                options={resourcesAvailableRaw}
                lectureSpanTimes={props.spanTimes}
                // Should filter resources when this is opened to only show available resources
              />
            </ModalInner>
          </ModalBox>
        </Modal>
      </BodyWrapper>
    </React.Fragment>
  );
};

export default SelectSessionsFlow;
