import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../services/use-auth";
import {collection, query, where, getDocs} from "firebase/firestore";

import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

import ScheduleCourse from "./scheduleCourse/schedule_course";
import InstitutionSetup from "./institutionSetup";
import ModifyResource from "./modify_resources/modifyResource";

const conditionAdmin = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];
const conditionAuthUser = (authUser) => !!authUser;

const ProtectedAdminPage = (props) => {
  const authHook = useAuth();

  const [institution, setInstitution] = useState({});
  const [courseObjects, setCourseObjects] = useState([]);

  const [resourceComp, setResourceComp] = useState("none");

  const institutionsRef = collection(authHook.db, "institutions");
  const instQueryOwner = query(
    institutionsRef,
    where("ownerEmail", "==", props.authUser.email)
  );
  const instQueryAdmin = query(
    institutionsRef,
    where("adminEmail", "==", props.authUser.email)
  );
  const instQuery = props.userData.roles.admin
    ? instQueryAdmin
    : props.userData.roles.owner
    ? instQueryOwner
    : null;
  useEffect(() => {
    const getInstitution = async () => {
      const querySnapshot = await getDocs(instQuery);
      if (querySnapshot.size === 0) {
        console.log("Didn't get any institutions");
      }
      querySnapshot.forEach((doc) => {
        console.log(`Got institution: ${doc.data().name}`);
        setInstitution({
          name: doc.data().name,
          docId: doc.id,
          labRate: doc.data().labFractionLHE,
        });
      });
    };
    props.setShowLoading(true);
    getInstitution();
  }, []);

  useEffect(() => {
    if (institution.docId) {
      let courses = [];
      const fetchInstitutionData = async () => {
        const coursesSnap = await getDocs(
          collection(authHook.db, "institutions", institution.docId, "courses")
        );
        coursesSnap.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          let course = doc.data();
          course["courseID"] = doc.id;
          courses.push(course);
        });
        setCourseObjects(courses);
        props.setShowLoading(false);
      };
      fetchInstitutionData();
    }
  }, [authHook.db, institution]);

  return (
    // Make a create new semester flow with => courses => Instructors => Rooms => Semester
    <React.Fragment>
      {institution.name && (
        <h3>
          Welcome {props.userData.username}, program owner for{" "}
          {institution.name}
        </h3>
      )}
      {props.userData.adminOnboard ? (
        <React.Fragment>
          {institution && (
            <ModifyResource
              institution={institution}
              resourceComp={resourceComp}
              setResourceComp={setResourceComp}
            />
          )}
          {resourceComp === "none" && (
            <ScheduleCourse
              courseObjects={courseObjects}
              institution={institution}
              setShowLoading={props.setShowLoading}
              authUser={props.authUser}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <InstitutionSetup
            institution={institution}
            authUser={props.authUser}
            setShowLoading={props.setShowLoading}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const AdminPage = (props) => {
  const navigate = useNavigate();
  //Redirect here if not admin
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  useEffect(() => {
    //If they are not logged in at all as an authUser
    if (!conditionAuthUser(props.authUser)) {
      console.log(`No authUser signed in ${props.authUser}`);
      navigate(ROUTES.SIGN_IN);
    } else if (!props.userData.roles || !conditionAdmin(props.userData)) {
      console.log("The authUser is not an admin:", props.userData.roles);
      navigate(ROUTES.HOME);
    } else {
      console.log(`This person is an admin.`);
    }
    if (props.userData) {
      if (props.userData.roles) {
        setIsAdmin(props.userData.roles[ROLES.ADMIN]);
      }
      setLoadingUser(false);
    }
  }, [navigate, props.authUser, props.userData]);
  return (
    <React.Fragment>
      {!loadingUser ? (
        isAdmin ? (
          <React.Fragment>
            <ProtectedAdminPage
              authUser={props.authUser}
              userData={props.userData}
              setShowLoading={props.setShowLoading}
            />
          </React.Fragment>
        ) : (
          <h1>You are an IMPOSTER!</h1>
        )
      ) : (
        <h1>Loading</h1>
      )}
    </React.Fragment>
  );
};

export default AdminPage;
