import React, {useState} from "react";
import SelectASemester from "./select_semester";
import SelectACourse from "./select_course";
import {
  ComponentInnerWrapper,
  ComponentOutterWrapper,
  LargeTitle,
  HeaderWrapper,
} from "./schedule_course_styles";
import SelectSessionsFlow from "./select_sessions_flow.js";

const ScheduleCourse = (props) => {
  const [semesterCat, setSemesterCat] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(undefined);

  const [spanTimes, setSpanTimes] = useState([]);
  const [lockTimeSpans, setLockTimeSpans] = useState(false);

  //Flow as follows: select semester or init new => select course & begin flow => display scheduling component
  return (
    <React.Fragment>
      <div style={{height: "40px"}} />
      <ComponentOutterWrapper>
        <ComponentInnerWrapper>
          {semesterCat === undefined ? (
            <LargeTitle>Schedule a Course</LargeTitle>
          ) : selectedCourse === undefined ? (
            <LargeTitle>Schedule a Course for {semesterCat.label}</LargeTitle>
          ) : (
            <LargeTitle>
              Schedule {selectedCourse.label} for {semesterCat.label}
            </LargeTitle>
          )}
          <HeaderWrapper>
            {semesterCat === undefined && (
              <SelectASemester
                institution={props.institution}
                setSemesterCat={setSemesterCat}
              />
            )}
          </HeaderWrapper>
          {semesterCat !== undefined && selectedCourse === undefined && (
            <SelectACourse
              institution={props.institution}
              setSelectedCourse={setSelectedCourse}
            />
          )}
          {semesterCat !== undefined &&
            selectedCourse !== undefined &&
            !lockTimeSpans && (
              <SelectSessionsFlow
                semesterCat={semesterCat}
                course={selectedCourse}
                institution={props.institution}
                spanTimes={spanTimes}
                setSpanTimes={setSpanTimes}
                lockTimeSpans={setLockTimeSpans}
              />
            )}
        </ComponentInnerWrapper>
      </ComponentOutterWrapper>
    </React.Fragment>
  );
};

export default ScheduleCourse;
