import React, {useEffect} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";
import {
  CustomSelect,
  StyledOption,
  Label,
  Text,
  SmallText,
} from "./schedule_course_styles";
import StyledButton from "../../../assets/buttons";

const SelectNumberOfSessions = (props) => {
  //Flow begins with selecting options for how many meetings for lecture & lab
  //Flow continues with displaying the week

  const findMeetingNumberOptions = () => {
    const lecLHE = props.course.lheLecture;
    const labHours = props.course.hoursLab;
    const lecChoicesArr = [];
    const labChoicesArr = [];
    for (let x = 1; x <= lecLHE; x++) {
      lecChoicesArr.push(x);
    }
    for (let x = 1; x <= labHours; x++) {
      labChoicesArr.push(x);
    }
    return {lecture: lecChoicesArr, lab: labChoicesArr};
  };
  const meetingNumberOptions = findMeetingNumberOptions();
  console.log(meetingNumberOptions);
  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  useEffect(() => {
    const calcSpanInMinutes = ({hours, meetings}) => {
      let lectureLength;
      if (hours < 2) {
        lectureLength = (hours * 50) / meetings;
      } else {
        lectureLength = (hours * 60) / meetings - 10;
      }
      return lectureLength;
    };
    props.setLectureTimeSpan(
      calcSpanInMinutes({
        hours: parseInt(props.course.lheLecture),
        meetings: props.numberOfMeetings.lecture,
      })
    );
    props.setLabTimeSpan(
      calcSpanInMinutes({
        hours: parseInt(props.course.hoursLab),
        meetings: props.numberOfMeetings.lab,
      })
    );
  }, [props.numberOfMeetings]);

  const toHoursAndMin = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return {hours: hours, minutes: minutesLeft};
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {meetingNumberOptions !== undefined && (
          <Box>
            <Label id="select_semester_options_label">
              Number of Lecture Meetings
            </Label>
            <CustomSelect
              value={props.numberOfMeetings.lecture}
              onChange={(e, newValue) =>
                props.setNumberOfMeetings((numberOfMeetings) => {
                  return {...numberOfMeetings, lecture: newValue};
                })
              }>
              {meetingNumberOptions.lecture.map((option) => (
                <StyledOption
                  value={option}
                  key={option.toString().concat("lecNumOpt")}>
                  {option === 1
                    ? `${option} lecture meeting`
                    : `${option} lecture meetings`}
                </StyledOption>
              ))}
            </CustomSelect>{" "}
            {props.numberOfMeetings.lecture > 1 ? (
              props.lectureTimeSpan >= 60 ? (
                <SmallText>
                  {toHoursAndMin(props.lectureTimeSpan).hours} hour and{" "}
                  {toHoursAndMin(props.lectureTimeSpan).minutes} minute lectures
                </SmallText>
              ) : (
                <Text>{props.lectureTimeSpan} minute lectures</Text>
              )
            ) : props.labTimeSpan >= 60 ? (
              <SmallText>
                {toHoursAndMin(props.lectureTimeSpan).hours} hour and{" "}
                {toHoursAndMin(props.lectureTimeSpan).minutes} minute lecture
              </SmallText>
            ) : (
              <Text>{props.lectureTimeSpan} minute lecture</Text>
            )}
          </Box>
        )}
        {meetingNumberOptions !== undefined &&
          meetingNumberOptions.lab.length > 0 && (
            <Box>
              <Label id="select_semester_options_label">
                Number of Lab Meetings
              </Label>
              <CustomSelect
                value={props.numberOfMeetings.lab}
                onChange={(e, newValue) =>
                  props.setNumberOfMeetings((numberOfMeetings) => {
                    return {...numberOfMeetings, lab: newValue};
                  })
                }>
                {meetingNumberOptions.lab.map((option) => (
                  <StyledOption
                    value={option}
                    key={option.toString().concat("labNumOpt")}>
                    {option === 1
                      ? `${option} lab meeting`
                      : `${option} lab meetings`}
                  </StyledOption>
                ))}
              </CustomSelect>{" "}
              {props.numberOfMeetings.lab > 1 ? (
                props.labTimeSpan >= 60 ? (
                  <SmallText>
                    {toHoursAndMin(props.labTimeSpan).hours} hour and{" "}
                    {toHoursAndMin(props.labTimeSpan).minutes} minute labs
                  </SmallText>
                ) : (
                  <Text>{props.labTimeSpan} minute labs</Text>
                )
              ) : props.labTimeSpan >= 60 ? (
                <SmallText>
                  {toHoursAndMin(props.labTimeSpan).hours} hour and{" "}
                  {toHoursAndMin(props.labTimeSpan).minutes} minute lab
                </SmallText>
              ) : (
                <Text>{props.labTimeSpan} minute lab</Text>
              )}
            </Box>
          )}
      </ThemeProvider>
      <StyledButton
        bright
        fontSize={"1.5rem"}
        height={"2.3rem"}
        width={"17rem"}
        callback={() => props.setConfirmNumberOfMeetings(true)}>
        Confirm Meetings
      </StyledButton>
    </React.Fragment>
  );
};

export default SelectNumberOfSessions;
