import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/use-auth";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import AnimatedForm from "../assets/forms";
import * as ROLES from "../constants/roles";

const conditionAdmin = (authUser) =>
  authUser && !!authUser.roles[ROLES.ADMIN_SUPER];
const conditionAuthUser = (authUser) => !!authUser;

const ProtectedAdminPage = (props) => {
  const [institutions, setInstitutions] = useState(undefined);
  const [gotInstitutions, setGotInstitutions] = useState(false);
  const [institutionCourseAdmins, setInstitutionCourseAdmins] =
    useState(undefined);

  const authHook = useAuth();

  useEffect(() => {
    if (props.authUser.uid) {
      const getInstitutions = async () => {
        const institutionsColl = collection(authHook.db, "institutions");
        const institutionsSnap = await getDocs(institutionsColl);
        const institutionNames = [];
        institutionsSnap.docs.forEach((inst) => {
          console.log(inst.data());
          institutionNames.push({
            value: inst.id,
            label: inst.data().name,
            courseAdmins: inst.data().courseAdmins,
          });
        });
        setInstitutions(institutionNames);
        setGotInstitutions(true);

        const users = collection(authHook.db, `users`);
        const q = query(
          users,
          where("email", "in", ["ryan.c.frese@gmail.com"])
        );
        const userObjs = await getDocs(q);
        console.log(userObjs.empty);
      };
      getInstitutions();
    }
  }, [authHook.db, props.authUser.uid]);

  // useEffect(() => {
  //   if (institutions && institutions.length > 0) {
  //     console.log(institutions);
  //     setGotInstitutions(true);
  //   }
  // }, [institutions]);

  const formInputs = [
    { name: "name", label: "Institution Name", type: "text" },
    { name: "owner", label: "Email Address of Owner", type: "text" },
    { name: "emailExt", label: "Employee Email Extention", type: "text" },
    { name: "labPercentage", label: "Lab Rate in Decimel (<1)", type: "text" },
  ];

  const formInputsAdd = [
    {
      name: "institution",
      label: "Institution",
      type: "radio",
      options: institutions,
    },
    {
      name: "adminEmail",
      label: "Admin's Email",
      type: "text",
    },
  ];

  const submitButtonStyle = { width: "260px", fontSize: "1.5rem" };

  //Create a new institution
  const createInstFunc = (inputs) => {
    const newInst = doc(
      authHook.db,
      "institutions",
      inputs.name.replace(/\s+/g, "_").toUpperCase()
    );
    setDoc(
      newInst,
      {
        name: inputs.name,
        ownerEmail: inputs.owner,
        emplEmailExt: inputs.emailExt,
        labPercentageLHE: parseFloat(inputs.labPercentage),
      },
      { merge: true }
    );
  };

  const createAdminFunc = (inputs) => {
    const courseAdmins = [];
    const thisInstitutionData = institutions.filter(
      (inst) => inst.value === inputs.institution
    );
    if (
      thisInstitutionData[0].courseAdmins &&
      thisInstitutionData[0].courseAdmins.length > 0
    ) {
      thisInstitutionData[0].courseAdmins.forEach((indivdualAdmin) =>
        courseAdmins.push(indivdualAdmin)
      );
    }
    const filteredAdmins = courseAdmins.filter(
      (admin) => admin === inputs.adminEmail
    );

    const alreadyEntered = filteredAdmins.length > 0;
    console.log(`matching: ${courseAdmins[0]} to ${inputs.adminEmail}`);
    if (!alreadyEntered) {
      courseAdmins.push(inputs.adminEmail);
    } else {
      console.log(`This person is already registered!`);
    }
    const newInstAdmin = doc(authHook.db, "institutions", inputs.institution);
    setDoc(newInstAdmin, { courseAdmins: courseAdmins }, { merge: true });
  };

  return (
    <React.Fragment>
      <AnimatedForm
        title={"Create Institution"}
        formInputs={formInputs}
        submitButtonText={"Create!"}
        submitFunction={createInstFunc}
        buttonStyle={submitButtonStyle}
        disableBox={"true"}
      />
      {gotInstitutions && (
        <AnimatedForm
          title={"Add Course Admin"}
          formInputs={formInputsAdd}
          submitButtonText={"Add Admin"}
          submitFunction={createAdminFunc}
          buttonStyle={submitButtonStyle}
          disableBox={"true"}
        />
      )}
    </React.Fragment>
  );
};

const AdminSuperPage = (props) => {
  const navigate = useNavigate();
  //Redirect here if not admin
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  useEffect(() => {
    //If they are not logged in at all as an authUser
    if (!conditionAuthUser(props.authUser)) {
      console.log(`No authUser signed in ${props.authUser}`);
      navigate("/signin");
    } else if (!props.userData.roles || !conditionAdmin(props.userData)) {
      console.log(`The authUser is not an admin ${props.userData.roles}`);
      console.log(`ConditionAdmin is: ${conditionAdmin(props.userData)}`);
      navigate("/home");
    } else {
      console.log(`This person is an admin.`);
    }
    if (props.userData) {
      if (props.userData.roles) {
        setIsAdmin(props.userData.roles[ROLES.ADMIN_SUPER]);
      }
      setLoadingUser(false);
    }
  }, [navigate, props.authUser, props.userData]);
  return (
    <React.Fragment>
      {!loadingUser ? (
        isAdmin ? (
          <ProtectedAdminPage authUser={props.authUser} />
        ) : (
          <h1>You are an IMPOSTER!</h1>
        )
      ) : (
        <h1>Loading</h1>
      )}
    </React.Fragment>
  );
};

export default AdminSuperPage;
