import React, {useState, useEffect} from "react";
import {useAuth} from "../../../services/use-auth";
import {setDoc, doc, collection, onSnapshot} from "firebase/firestore";
import {createTheme, ThemeProvider} from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  CustomSelect,
  ModalBox,
  ModalInner,
  StyledOption,
  MedDarkTitle,
  Label,
} from "./schedule_course_styles";
import StyledButton from "../../../assets/buttons";

const CreateASemester = (props) => {
  const [values, setValues] = useState({year: "", semester: ""});
  const [entryErrors, setEntryErrors] = useState({
    year: false,
    semester: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    year: null,
    semester: null,
  });

  const authHook = useAuth();

  const handleChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const createSemester = () => {
    let semester = values.semester.concat("_").concat(values.year);
    const newCatRef = doc(
      authHook.db,
      "institutions",
      props.institution.docId,
      "catalogs",
      semester
    );

    setDoc(newCatRef, {initOn: Date.now()});
    props.handleCloseAddCat();
  };

  const validateYear = () => {
    if (values.year === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        year: "You must enter a year",
      }));
    } else {
      const yearInt = parseInt(values.year);
      const isValidNumber = !isNaN(yearInt);
      console.log(yearInt, isValidNumber);
      if (!isValidNumber) {
        setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          year: "You must enter a valid number for year",
        }));
      } else {
        if (yearInt < 2022) {
          setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            year: "This year is already in the past",
          }));
        } else {
          if (yearInt > 2050) {
            setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
            setEntryFeedback((entryFeedback) => ({
              ...entryFeedback,
              year: "This is a bit too far in the future...",
            }));
          } else {
            setEntryErrors((entryErrors) => ({...entryErrors, year: false}));
            setEntryFeedback((entryFeedback) => ({
              ...entryFeedback,
              year: null,
            }));
          }
        }
      }
    }
  };

  const semesters = [
    {value: "SPRING", label: "Spring"},
    {value: "FALL", label: "Fall"},
  ];

  const invalid = entryErrors.year || entryErrors.semester;

  return (
    <React.Fragment>
      <MedDarkTitle>Initialize New Catalog</MedDarkTitle>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Year"
          name="year"
          variant="outlined"
          color="secondary"
          value={values["year"]}
          onChange={handleChange}
          onBlur={() => validateYear()}
          error={entryErrors.year}
          helperText={entryFeedback.year}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Semester"
          name="semester"
          color="secondary"
          select
          value={values["semester"]}
          onChange={handleChange}>
          {semesters.map((semesterOpt) => (
            <MenuItem key={semesterOpt.value} value={semesterOpt.value}>
              {semesterOpt.label}
            </MenuItem>
          ))}
        </TextField>
        <StyledButton
          disabled={invalid}
          primary
          callback={() => createSemester()}
          fontSize={"1.25rem"}
          height={"2.3rem"}
          width={"19rem"}>
          Initialize Semester!
        </StyledButton>
      </Box>
    </React.Fragment>
  );
};

const SelectASemester = (props) => {
  const [selectedSemester, setSelectedSemester] = useState(undefined);
  const [semesterOptions, setSemesterOptions] = useState([]);

  const [addCat, setAddCat] = useState(false);
  const handleOpenAddCat = () => setAddCat(true);
  const handleCloseAddCat = () => setAddCat(false);

  const authHook = useAuth();

  useEffect(() => {
    if (props.institution.docId) {
      const collectionRef = collection(
        authHook.db,
        "institutions",
        props.institution.docId,
        "catalogs"
      );
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const semestersArr = [];
        snapshot.docChanges().forEach((semesterDoc) => {
          const semesterText = semesterDoc.doc.id.split("_")[0];
          const yearText = semesterDoc.doc.id.split("_")[1];
          const semesterFormated =
            semesterText.toLowerCase().charAt(0).toUpperCase() +
            semesterText.toLowerCase().slice(1);
          const label = semesterFormated.concat(" ").concat(yearText);
          semestersArr.push({
            year: yearText,
            semester: semesterText,
            id: semesterDoc.doc.id,
            label: label,
          });
        });
        const now = new Date();
        let thisSemester;
        if (now.getMonth() + 1 > 6) {
          thisSemester = "FALL";
        } else {
          thisSemester = "SPRING";
        }
        const thisSemesterID = thisSemester
          .concat("_")
          .concat(now.getFullYear());
        const nextSemester = new Date(now.setMonth(now.getMonth() + 6));
        let semester;
        if (nextSemester.getMonth() + 1 > 6) {
          semester = "FALL";
        } else {
          semester = "SPRING";
        }
        let year = nextSemester.getFullYear();
        const nextSemesterID = semester.concat("_").concat(year);
        const nextSemesterCat = semestersArr.filter(
          (semester) => nextSemesterID === semester.id
        );
        const thisSemesterCat = semestersArr.filter(
          (semester) => thisSemesterID === semester.id
        );
        if (nextSemesterCat.length === 1) {
          setSelectedSemester(nextSemesterCat[0]);
        } else if (thisSemesterCat.length === 1) {
          setSelectedSemester(thisSemesterCat[0]);
        } else {
          setSelectedSemester(semestersArr[0]);
        }
        setSemesterOptions((semesterOptions) =>
          [...semesterOptions, ...semestersArr].sort((a, b) =>
            a.year > b.year ? 1 : a.semester > b.semester ? -1 : 0
          )
        );
      });
      return () => {
        unsubscribe();
      };
    }
  }, [authHook.db, props.institution.docId]);

  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  return (
    <React.Fragment>
      {semesterOptions &&
        semesterOptions.length > 0 &&
        selectedSemester !== undefined && (
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <Box>
                <Label id="select_semester_options_label">
                  Select Semester
                </Label>
                <CustomSelect
                  value={selectedSemester.id}
                  onChange={(e, semesterId) =>
                    setSelectedSemester(
                      semesterOptions.filter(
                        (semesterItem) => semesterItem.id === semesterId
                      )[0]
                    )
                  }>
                  {semesterOptions.map((option) => (
                    <StyledOption value={option.id} key={option.id}>
                      {option.label}
                    </StyledOption>
                  ))}
                </CustomSelect>{" "}
              </Box>
            </ThemeProvider>
            <StyledButton
              bright
              fontSize={"1.25rem"}
              height={"2.3rem"}
              width={"19rem"}
              callback={() => props.setSemesterCat(selectedSemester)}>
              Schedule in {selectedSemester.label}
            </StyledButton>
          </React.Fragment>
        )}
      <StyledButton
        bright
        fontSize={"1.25rem"}
        height={"2.3rem"}
        width={"17rem"}
        callback={() => handleOpenAddCat()}>
        Create New Semester
      </StyledButton>
      <Modal open={addCat} onClose={handleCloseAddCat}>
        <ModalBox>
          <ModalInner>
            <CreateASemester
              institution={props.institution}
              handleCloseAddCat={handleCloseAddCat}
            />
          </ModalInner>
        </ModalBox>
      </Modal>
    </React.Fragment>
  );
};

export default SelectASemester;
