import styled from "@emotion/styled";

export const DayLabel = styled.p`
  font-size: 22px;
  font-family: "MontserratBold";
  color: white;
`;

export const HourLabel = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
  position: absolute;
  width: 13vw;
  text-align: center;
  pointer-events: none;
  z-index: 2;
`;

export const HourDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 13vw;
  border: 1px solid #bfbfbf;
  pointer-events: none;
`;

export const DayDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 13vw;
  align-items: center;
  pointer-events: none;
`;
