import React, {useEffect} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

import * as ROUTES from "../constants/routes";
import OnSignupComponent from "./onSignupFlow/on_signup";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const HomePage = (props) => {
  const redirect = useNavigate();
  console.log(`Onboarded: ${props.userData.onboarded}`);
  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser === null) {
        console.log("Should redirect to SIGN_IN");
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.stillLoading, props.authUser]);

  return (
    <PageWrapper>
      {!props.userData.onboarded && props.userData.roles && (
        <OnSignupComponent
          authUser={props.authUser}
          userData={props.userData}
        />
      )}

      {props.userData.onboarded && (
        <h3>Thanks for completing the onboarding process</h3>
      )}
    </PageWrapper>
  );
};

export default HomePage;
