import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import React, {useState, useEffect} from "react";
import AcceptOnboard from "./accept_onboard";
import CreateInst from "./create_institution";

import OnwerOnboardFlow from "./owner_onboard";
import {
  Title,
  SearchWrapper,
  SearchLogoStyled,
  SearchText,
  OnboardingInnerWrapper,
  OnboardingWrapper,
} from "./signup_flow_css";

const OnSignupComponent = (props) => {
  const [searching, setSearching] = useState(
    !props.userData.processedLookForInst
  );

  useEffect(() => {
    setSearching(!props.userData.processedLookForInst);
  }, [props.userData]);

  const isOwner = props.userData.roles.owner;

  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  return (
    <React.Fragment>
      {searching ? (
        <SearchWrapper>
          <SearchLogoStyled />
          <SearchText>Checking Institutions...</SearchText>
        </SearchWrapper>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <OnboardingWrapper>
            <OnboardingInnerWrapper>
              <Title>
                Hello {props.userData.username}, please complete the onboarding
                process
              </Title>
              {!props.userData.onboarded &&
                props.userData.partOfAnInst &&
                !isOwner && (
                  <AcceptOnboard
                    authUser={props.authUser}
                    userData={props.userData}
                  />
                )}
              {!props.userData.onboarded && !props.userData.partOfAnInst && (
                <CreateInst
                  authUser={props.authUser}
                  userData={props.userData}
                />
              )}
              {!props.userData.onboarded && isOwner && (
                <OnwerOnboardFlow
                  userData={props.userData}
                  authUser={props.authUser}
                />
              )}
            </OnboardingInnerWrapper>
          </OnboardingWrapper>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default OnSignupComponent;
