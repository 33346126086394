import { availabilityUtility } from "./availabilityUtility";
import { compiler } from "./getCompilation";

const hoursConst = { firstHour: 7, lastHour: 21 };

export const refineResources = ({
  resources,
  spanNeeded,
  potentialLectureTime,
  requirements,
  selectedOptions,
  setSelected,
  updateList,
}) => {
  let roomResources;
  if (requirements) {
    //Refine classrooms to only contain labs
    roomResources = resources.rooms.filter(
      (room) => room.labTag === requirements
    );
  } else {
    roomResources = resources.rooms;
  }

  //Inputs should be raw resources and  current array of lectures,
  //  outputs will be the refined resources to fit this new selection
  // console.log(spanNeeded);
  let instructorsInitialAvailability = [];
  resources.instructors.forEach((instructor) => {
    instructorsInitialAvailability.push(
      availabilityUtility({
        purpose: "instructors",
        element: instructor,
        spanNeeded: spanNeeded,
      })
    );
  });
  let classroomsInitialAvailability = [];
  roomResources.forEach((room) => {
    classroomsInitialAvailability.push(
      availabilityUtility({
        purpose: "rooms",
        element: room,
        spanNeeded: spanNeeded,
      })
    );
  });

  //Get Institutional default week structure
  const defaultWeekTemplate =
    instructorsInitialAvailability[0].daysAvailability;
  let defaultWeek = [];
  for (let i = 0; i < defaultWeekTemplate.length; i++) {
    const day = defaultWeekTemplate[i];
    const hoursInDay = [];
    for (let j = 0; j + hoursConst.firstHour <= hoursConst.lastHour; j++) {
      hoursInDay.push({ hour: j + hoursConst.firstHour });
    }
    defaultWeek.push({ day: day.day, hours: hoursInDay });
  }

  //Filter out instructors who aren't available during selected lecture times
  let instructorsWithSelectedTimes;
  if (selectedOptions.classSpanTimes.length > 0) {
    //Some lecture span times have been selected
    const arrOfLecturesSelectedInstructorAvail = [];
    //Build an array of available instructors from each lecture time
    selectedOptions.classSpanTimes.forEach((lectureTime) => {
      const instructorsWithSelectedTimeSingular =
        instructorsInitialAvailability.filter((instructor) => {
          let instructorFits;
          const thisDay = instructor.daysAvailability.filter(
            (day) => day.day === lectureTime.day
          )[0];
          if (thisDay && thisDay.availableAllDay) {
            instructorFits = true;
          } else {
            //Check if this portential lecture fits the availability of instructor.
            const intervalForHour = thisDay.availabilityIntervals.filter(
              (interval) =>
                interval.initialHour <= lectureTime.hourInit &&
                interval.finalHour >= lectureTime.hourFinal
            )[0];
            if (intervalForHour !== undefined) {
              //Check the minutes to see if it truly fits
              instructorFits =
                (intervalForHour.initialHour < lectureTime.hourInit ||
                  (intervalForHour.initialHour === lectureTime.hourInit &&
                    intervalForHour.initialMinute < lectureTime.minuteInit)) &&
                (intervalForHour.finalHour > lectureTime.hourFinal ||
                  (intervalForHour.finalHour === lectureTime.hourFinal &&
                    intervalForHour.finalMinute > lectureTime.minuteFinal));

            } else {
              instructorFits = false;
            }
          }
          return instructorFits;
        });
      arrOfLecturesSelectedInstructorAvail.push(
        instructorsWithSelectedTimeSingular
      );
    });
    // console.log(arrOfLecturesSelectedInstructorAvail);
    instructorsWithSelectedTimes = instructorsInitialAvailability.filter(
      //Return instructors who are available at all lecture times
      (instructor) => {
        //Check to see if the instructor is available at all lecture times
        const thisInstructorAvailAllTimesSelected =
          arrOfLecturesSelectedInstructorAvail.every((instructorsAvail) => {
            //within the array of lecture times selected now
            const instructorFilteredArr = instructorsAvail.filter(
              (instructorSingularAvail) =>
                instructorSingularAvail.instructor === instructor.instructor
            );
            // console.log(instructorFilteredArr);
            //There will be an instructor in this array if this instructor is available at this lecture time
            return instructorFilteredArr.length > 0;
          });
        return thisInstructorAvailAllTimesSelected;
      }
    );
  } else {
    instructorsWithSelectedTimes = instructorsInitialAvailability;
  }
  // console.log(instructorsWithSelectedTimes);

  //Filter out classrooms who aren't available during selected lecture times
  let classroomsWithSelectedTimes;
  if (selectedOptions.classSpanTimes.length > 0) {
    //Some lecture span times have been selected
    const arrOfLecturesSelectedClassroomAvail = [];
    //Build an array of available classrooms from each lecture time
    selectedOptions.classSpanTimes.forEach((lectureTime) => {
      const classroomsWithSelectedTimeSingular =
        classroomsInitialAvailability.filter((classroom) => {
          let classroomFits;
          const thisDay = classroom.daysAvailability.filter(
            (day) => day.day === lectureTime.day
          )[0];
          if (thisDay && thisDay.availableAllDay) {
            classroomFits = true;
          } else {
            //Check if this portential lecture fits the availability of classroom.
            const intervalForHour = thisDay.availabilityIntervals.filter(
              (interval) =>
                interval.initialHour <= lectureTime.hourInit &&
                interval.finalHour >= lectureTime.hourFinal
            )[0];

            if (intervalForHour !== undefined) {
              //Check the minutes to see if it truly fits

              classroomFits =
                (intervalForHour.initialHour < lectureTime.hourInit ||
                  (intervalForHour.initialHour === lectureTime.hourInit &&
                    intervalForHour.initialMinute < lectureTime.minuteInit)) &&
                (intervalForHour.finalHour > lectureTime.hourFinal ||
                  (intervalForHour.finalHour === lectureTime.hourFinal &&
                    intervalForHour.finalMinute > lectureTime.minuteFinal));
            } else {
              classroomFits = false;
            }
          }
          return classroomFits;
        });
      arrOfLecturesSelectedClassroomAvail.push(
        classroomsWithSelectedTimeSingular
      );
    });
    // console.log(arrOfLecturesSelectedClassroomAvail);
    classroomsWithSelectedTimes = classroomsInitialAvailability.filter(
      //Return classrooms who are available at all lecture times
      (classroom) => {
        //Check to see if the classroom is available at all lecture times
        const thisClassroomAvailAllTimesSelected =
          arrOfLecturesSelectedClassroomAvail.every((classroomsAvail) => {
            //within the array of lecture times selected now
            const classroomFilteredArr = classroomsAvail.filter(
              (classroomSingularAvail) =>
                classroomSingularAvail.room === classroom.room
            );
            // console.log(classroomFilteredArr);
            //There will be an classroom in this array if this classroom is available at this lecture time
            return classroomFilteredArr.length > 0;
          });
        return thisClassroomAvailAllTimesSelected;
      }
    );
  } else {
    classroomsWithSelectedTimes = classroomsInitialAvailability;
  }
  // console.log(classroomsWithSelectedTimes);
  if (!updateList) {
    //Filter out instructors who aren't available during hovered lecture/lab time
    const instructorsWithHoveredTimes = instructorsInitialAvailability.filter(
      (instructor) => {
        let instructorFits;
        const thisDay = instructor.daysAvailability.filter(
          (day) => day.day === potentialLectureTime.day
        )[0];
        if (thisDay && thisDay.availableAllDay) {
          instructorFits = true;
        } else {
          //Check if this portential lecture fits the availability of instructor.
          const intervalForHour = thisDay.availabilityIntervals.filter(
            (interval) =>
              interval.initialHour <= potentialLectureTime.hourInit &&
              interval.finalHour >= potentialLectureTime.hourFinal
          )[0];
          if (intervalForHour !== undefined) {
            //Check the minutes to see if it truly fits
            instructorFits =
              (intervalForHour.initialHour < potentialLectureTime.hourInit ||
                (intervalForHour.initialHour ===
                  potentialLectureTime.hourInit &&
                  intervalForHour.initialMinute <
                    potentialLectureTime.minuteInit)) &&
              (intervalForHour.finalHour > potentialLectureTime.hourFinal ||
                (intervalForHour.finalHour === potentialLectureTime.hourFinal &&
                  intervalForHour.finalMinute >
                    potentialLectureTime.minuteFinal));
          } else {
            instructorFits = false;
          }
        }
        return instructorFits;
      }
    );

    //create a new array of classrooms that exists in both
    //Filter each array with the other, to recieve only elements that are in both
    const combinedFilteredAndHoveredInstructorAvail =
      instructorsWithSelectedTimes.filter((element) =>
        instructorsWithHoveredTimes.includes(element)
      );

    //Filter out classrooms who aren't available during hovered lecture/lab time
    const classroomsWithHoveredTimes = classroomsInitialAvailability.filter(
      (instructor) => {
        let instructorFits;
        const thisDay = instructor.daysAvailability.filter(
          (day) => day.day === potentialLectureTime.day
        )[0];
        if (thisDay && thisDay.availableAllDay) {
          instructorFits = true;
        } else {
          //Check if this portential lecture fits the availability of instructor.
          const intervalForHour = thisDay.availabilityIntervals.filter(
            (interval) =>
              interval.initialHour <= potentialLectureTime.hourInit &&
              interval.finalHour >= potentialLectureTime.hourFinal
          )[0];
          if (intervalForHour !== undefined) {
            //Check the minutes to see if it truly fits
            instructorFits =
              (intervalForHour.initialHour < potentialLectureTime.hourInit ||
                (intervalForHour.initialHour ===
                  potentialLectureTime.hourInit &&
                  intervalForHour.initialMinute <
                    potentialLectureTime.minuteInit)) &&
              (intervalForHour.finalHour > potentialLectureTime.hourFinal ||
                (intervalForHour.finalHour === potentialLectureTime.hourFinal &&
                  intervalForHour.initialMinute >
                    potentialLectureTime.minuteFinal));
          } else {
            instructorFits = false;
          }
        }
        return instructorFits;
      }
    );

    //create a new array of classrooms that exists in both
    //Filter each array with the other, to recieve only elements that are in both
    const combinedFilteredAndHoveredClassroomAvail =
      classroomsWithSelectedTimes.filter((element) =>
        classroomsWithHoveredTimes.includes(element)
      );

    // setSelected({
    //   classrooms: classroomsWithSelectedTimes,
    //   instructors: instructorsWithSelectedTimes,
    // });

    const availabilityCompiled = compiler({
      defaultWeek: defaultWeek,
      instructorsAvailability: combinedFilteredAndHoveredInstructorAvail,
      classroomAvailability: combinedFilteredAndHoveredClassroomAvail,
    });

    return availabilityCompiled;
  }
  // console.log(availabilityCompiled);
  //Return refined resources so they can be set to the new state of refined resources
  if (updateList) {
    const availInstructorList = [];
    instructorsWithSelectedTimes.forEach((instructor) =>
      availInstructorList.push(instructor.instructorID)
    );
    const availClassroomList = [];
    classroomsWithSelectedTimes.forEach((classroom) =>
      availClassroomList.push(classroom.instructorID)
    );
    return {
      instructorList: availInstructorList,
      roomList: availClassroomList,
    };
  }
};
