import React, {useState, useEffect} from "react";
import {ProvideAuth, useAuth} from "./services/use-auth";
import {onAuthStateChanged} from "firebase/auth";
import HomePage from "./scenes/home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {doc, onSnapshot} from "firebase/firestore";

import * as ROUTES from "./constants/routes";
import Nav from "./scenes/nav";
import SignInPage from "./scenes/session_management/signIn";
import SignUpPage from "./scenes/session_management/signUp";
import AccountPage from "./scenes/session_management/account";
import AdminSuperPage from "./scenes/adminSuper";
import AdminPage from "./scenes/institution_admin/admin";
import NoMatch from "./scenes/no_match";

const App = () => {
  const AppInSession = () => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState({});
    const [showLoading, setShowLoading] = useState(true);

    const authHook = useAuth();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(authHook.auth, (user) => {
        console.log(`The auth listener has triggered in the App.js file`);
        if (user) {
          console.log(`There appears to be a user with UID: ${user.uid}`);
          setUser(user);
        } else {
          setUser(null);
          setUserData({});
          setShowLoading(false);
        }
      });
      return () => {
        unsubscribe();
      };
    }, [authHook.auth]);

    useEffect(() => {
      if (user) {
        const unsub = onSnapshot(doc(authHook.db, "users", user.uid), (doc) => {
          console.log("Got new user data: ", doc.data());
          setUserData(doc.data());
          setShowLoading(false);
        });
        return () => {
          unsub();
        };
      }
    }, [authHook.db, user]);

    return (
      <BrowserRouter>
        {/*All route components go here and can recieve auth state */}
        <Nav authUser={user} userData={userData} showLoading={showLoading} />
        <Routes>
          <Route
            exact
            path={ROUTES.HOME}
            element={
              <HomePage
                authUser={user}
                userData={userData}
                stillLoading={showLoading}
              />
            }
          />
          <Route
            exact
            path={ROUTES.SIGN_IN}
            element={<SignInPage stillLoading={showLoading} />}
          />
          <Route
            exact
            path={ROUTES.SIGN_UP}
            element={<SignUpPage stillLoading={showLoading} />}
          />
          <Route
            exact
            path={ROUTES.ACCOUNT}
            element={<AccountPage authUser={user} stillLoading={showLoading} />}
          />

          <Route
            exact
            path={ROUTES.ADMIN_SUPER}
            element={
              <AdminSuperPage
                authUser={user}
                userData={userData}
                stillLoading={showLoading}
              />
            }
          />
          <Route
            exact
            path={ROUTES.ADMIN}
            element={
              <AdminPage
                authUser={user}
                userData={userData}
                setShowLoading={setShowLoading}
                stillLoading={showLoading}
              />
            }
          />
          <Route
            path="*"
            element={<NoMatch authUser={user} stillLoading={showLoading} />}
          />
        </Routes>
      </BrowserRouter>
    );
  };
  return (
    <ProvideAuth>
      <AppInSession />
    </ProvideAuth>
  );
};

export default App;
