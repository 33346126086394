import React, {useState, useEffect} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {
  setDoc,
  doc,
  collection,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useAuth} from "../../../services/use-auth";
import StyledButton from "../../../assets/buttons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ComputerIcon from "@mui/icons-material/Computer";
import BiotechIcon from "@mui/icons-material/Biotech";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Text,
  ComponentInnerWrapper,
  ComponentOutterWrapper,
  FooterWrapper,
  HeaderWrapper,
  Table,
  TableHeader,
  LoadingLogoComp,
  TitleText,
  ModalBox,
  ModalInner,
  LargeTitle,
} from "./modify_resources_css";
import {ConfirmAction} from "../../../assets/controls";
import DomainAdd from "@mui/icons-material/DomainAdd";
import Tooltip from "@mui/material/Tooltip";

const AddOrChangeRoom = (props) => {
  const [values, setValues] = useState({
    campus: "",
    building: "",
    number: "",
    areas: "",
    computerLab: false,
    labForArea: false,
    seats: "",
  });
  const [entryErrors, setEntryErrors] = useState({
    campus: false,
    building: false,
    number: false,
    areas: false,
    seats: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    campus: false,
    building: false,
    number: false,
    areas: false,
    seats: false,
  });
  const [areasAsArr, setAreasAsArr] = useState([]);

  const [invalid, setInvalid] = useState(true);

  useEffect(() => {
    if (props.dataIn && props.dataIn.id.length > 0 && values.campus === "") {
      const labForArea =
        props.dataIn.labAreasAttached !== "false" &&
        props.dataIn.labAreasAttached !== false;
      const tempValues = {
        campus: props.dataIn.campus,
        building: props.dataIn.building,
        number: props.dataIn.roomNumber,
        seats: props.dataIn.seats,
        computerLab: props.dataIn.computerLab || false,
        areas: props.dataIn.areasWithPermissions.join(", "),
      };
      if (labForArea && props.dataIn.labAreasAttached.length > 0) {
        //TODO: go through values and assign true to display in switches
        tempValues["labForArea"] = true;
        props.dataIn.areasWithPermissions.forEach((area) => {
          if (props.dataIn.labAreasAttached.includes(area)) {
            tempValues[area] = true;
          } else {
            tempValues[area] = false;
          }
        });
      } else {
        tempValues["labForArea"] = false;
      }
      setValues(tempValues);
      console.log(tempValues);
    }
  }, [props.dataIn, values.campus]);

  useEffect(() => {
    const areasArrTemp = values.areas
      .replace(/\s+/g, "")
      .toUpperCase()
      .split(",")
      .filter((item) => item !== "" && item.length === 4);
    setAreasAsArr(areasArrTemp);
  }, [values.areas]);

  const handleChange = (event) => {
    if (
      event.target.name === "computerLab" ||
      event.target.name === "labForArea"
    ) {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    } else if (event.target.name === "areas") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.toUpperCase(),
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleChangeSwitch = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
  };

  const validateCampus = () => {
    if (values.campus === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, campus: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        campus: "You must enter areas",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, campus: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        campus: null,
      }));
    }
  };

  const validateBuilding = () => {
    if (values.building === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, building: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        building: "You must enter areas",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, building: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        building: null,
      }));
    }
  };

  const validateNumber = () => {
    if (values.number === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, number: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        number: "You must enter areas",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, number: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        number: null,
      }));
    }
  };

  useEffect(() => {
    const invalidArr = [];

    for (const [key, value] of Object.entries(entryErrors)) {
      invalidArr.push(value);
    }

    setInvalid(!values.building || invalidArr.some((err) => err));
  }, [entryErrors, values.building]);

  const addRoom = async (values) => {
    console.log("Adding: ", values);
    props.closeModal();
    const docRef = doc(
      props.authHook.db,
      "institutions",
      props.institution.docId,
      "campus",
      values.campus.toUpperCase().replace(" ", "_"),
      "buildings",
      values.building.toUpperCase().replace(" ", "_"),
      "rooms",
      values.number
    );
    const seats = Number(values.seats);
    const areas = values.areas
      .replace(/\s+/g, "")
      .toUpperCase()
      .split(",")
      .filter((item) => item !== "");
    const areasLab = areas.filter((area) => values[area]);
    console.log("labs", areasLab);
    const roomData = {
      campus: values.campus,
      building: values.building,
      roomNumber: values.number,
      areasWithPermissions: areas,
      computerLab: values.computerLab,
      labForArea: values.labForArea || false,
      seats: seats,
      labAreasAttached: areasLab,
    };
    console.log("Adding: ", roomData);
    await setDoc(docRef, roomData);
  };

  const validateAreas = () => {
    if (values.areas === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, areas: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        areas: "You must enter areas",
      }));
    } else {
      const areasArr = values.areas
        .replace(/\s+/g, "")
        .toUpperCase()
        .split(",")
        .filter((item) => item !== "");
      console.log(areasArr);
      const hasFourLetters = areasArr.every(
        (areaCode) => areaCode.length === 4
      );
      const isOnlyLetters = areasArr.every((areaCode) =>
        /^[A-Z]+$/.test(areaCode)
      );
      if (!hasFourLetters) {
        setEntryErrors((entryErrors) => ({...entryErrors, areas: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          areas: "Each area must have 4 letters, separated by a comma",
        }));
      } else {
        if (!isOnlyLetters) {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            areas: true,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            areas: "Areas can only have letters",
          }));
        } else {
          setEntryErrors((entryErrors) => ({...entryErrors, areas: false}));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            areas: null,
          }));
        }
      }
    }
  };

  const validateSeats = () => {
    if (values.seats === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, seats: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        seats: "You must enter a number of seats",
      }));
    } else {
      if (isNaN(parseFloat(values.seats))) {
        setEntryErrors((entryErrors) => ({...entryErrors, seats: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          seats: "Seats must be a number",
        }));
      } else {
        if (parseFloat(values.seats) !== parseInt(values.seats)) {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            seats: true,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            seats: "Number of seats must be a whole number",
          }));
        } else {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            seats: false,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            seats: null,
          }));
        }
      }
    }
  };

  const editing = props.dataIn && Object.entries(props.dataIn).length > 0;
  return (
    <React.Fragment>
      <TitleText>Add or Edit Rooms</TitleText>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Campus"
          name="campus"
          variant="outlined"
          color="secondary"
          value={values["campus"]}
          onChange={handleChange}
          onBlur={() => validateCampus()}
          error={entryErrors.campus}
          helperText={
            props.dataIn
              ? "Campus cannot be changed for existing rooms"
              : entryFeedback.campus
          }
          disabled={editing}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Building"
          name="building"
          variant="outlined"
          color="secondary"
          value={values["building"]}
          onChange={handleChange}
          onBlur={() => validateBuilding()}
          error={entryErrors.building}
          helperText={
            props.dataIn
              ? "Building cannot be changed for existing rooms"
              : entryFeedback.building
          }
          disabled={editing}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Room Number"
          name="number"
          variant="outlined"
          color="secondary"
          value={values["number"]}
          onChange={handleChange}
          onBlur={() => validateNumber()}
          error={entryErrors.number}
          helperText={
            props.dataIn
              ? "Room number cannot be changed for existing rooms"
              : entryFeedback.number
          }
          disabled={editing}
        />
        <Tooltip title="List areas seperated by a comma" arrow>
          <TextField
            sx={{width: "350px", fontSize: "22px"}}
            label="Areas with Ownership"
            name="areas"
            variant="outlined"
            color="secondary"
            value={values["areas"]}
            onChange={handleChange}
            onBlur={() => validateAreas()}
            error={entryErrors.areas}
            helperText={entryFeedback.areas}
          />
        </Tooltip>
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Number of Seats"
          name="seats"
          variant="outlined"
          color="secondary"
          value={values["seats"]}
          onChange={handleChange}
          onBlur={() => validateSeats()}
          error={entryErrors.seats}
          helperText={entryFeedback.seats}
        />
        <FormControlLabel
          control={
            <Switch
              name={"computerLab"}
              checked={values["computerLab"]}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Computer Lab?"
        />
        <FormControlLabel
          control={
            <Switch
              name={"labForArea"}
              checked={values["labForArea"]}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Area Specific Lab?"
        />
        {values.labForArea &&
          areasAsArr.length > 0 &&
          areasAsArr.map((areaSwitch) => (
            <FormControlLabel
              key={areaSwitch.concat("label")}
              control={
                <Switch
                  key={areaSwitch.concat("switch")}
                  name={areaSwitch}
                  checked={values[areaSwitch]}
                  onChange={handleChangeSwitch}
                  color="secondary"
                />
              }
              label={areaSwitch.concat(" Lab")}
            />
          ))}
        <StyledButton
          disabled={invalid}
          primary
          callback={() => addRoom(values)}
          width="300px"
          fontSize="26px">
          {props.buttonText} <DomainAdd style={{paddingLeft: "12px"}} />
        </StyledButton>
      </Box>
    </React.Fragment>
  );
};

const Rooms = (props) => {
  const [rooms, setRooms] = useState([]);
  const [roomDataToEdit, setRoomDataToEdit] = useState({});
  const [roomToDelete, setRoomToDelete] = useState(undefined);
  const [numberOfRoomsToDisplay, setNumberOfRoomsToDisplay] = useState(10);
  const [refineRooms, setRefineRooms] = useState("");
  const [filteredRooms, setFilteredRooms] = useState([]);

  const [openAddRoom, setOpenAddRoom] = useState(false);
  const handleOpenAddRoom = () => setOpenAddRoom(true);
  const handleCloseAddRoom = () => setOpenAddRoom(false);

  const [openEditRoom, setOpenEditRoom] = useState(false);
  const handleOpenEditRoom = () => setOpenEditRoom(true);
  const handleCloseEditRoom = () => {
    setRoomDataToEdit({});
    setOpenEditRoom(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const authHook = useAuth();

  useEffect(() => {
    if (props.institution.docId) {
      const collectionRef = collection(
        authHook.db,
        "institutions",
        props.institution.docId,
        "roomsList"
      );

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const newRooms = [];
        const removedRooms = [];
        const changedRooms = [];
        snapshot.docChanges().forEach((change) => {
          console.log(change);
          if (change.type === "added") {
            console.log("New room: ", change.doc.data());
            if (!rooms.some((room) => change.doc.id === room.id)) {
              const newRoom = change.doc.data();
              newRoom["id"] = change.doc.id;
              newRooms.push(newRoom);
            }
          }
          if (change.type === "modified") {
            console.log("Modified room: ", change.doc.data());
            const changedRoom = change.doc.data();
            changedRoom["id"] = change.doc.id;
            changedRooms.push(changedRoom);
          }
          if (change.type === "removed") {
            console.log("Removed room: ", change.doc.id);
            removedRooms.push(change.doc.id);
          }
        });
        setRooms((rooms) => {
          rooms.filter((room) =>
            removedRooms.some((removedItem) => removedItem !== room.id)
          );
          return [
            ...rooms.filter(
              (room) =>
                (removedRooms.length === 0 && changedRooms.length === 0) ||
                removedRooms.some((removedItem) => removedItem !== room.id) ||
                changedRooms.some((alteredItem) => alteredItem.id !== room.id)
            ),
            ...newRooms,
            ...changedRooms,
          ];
        });
      });

      return () => {
        unsubscribe();
      };
    }
  }, [authHook.db, props.institution.docId]);

  useEffect(() => {
    if (refineRooms) {
      let filteredTemp = rooms.filter((room) =>
        room.name.toLowerCase().includes(refineRooms.toLowerCase())
      );
      setFilteredRooms(filteredTemp.sort((a, b) => (a.area > b.area ? 1 : -1)));
    } else {
      setFilteredRooms(
        rooms.sort((a, b) => {
          if (a.area !== b.area) {
            return a.area > b.area ? 1 : -1;
          } else {
            return a.roomNumber > b.roomNumber ? 1 : -1;
          }
        })
      );
    }
  }, [rooms, refineRooms]);

  const removeRoom = (dataIn) => {
    console.log(`Removing room: ${dataIn.data}`);

    deleteDoc(
      doc(
        authHook.db,
        "institutions",
        props.institution.docId,
        "campus",
        dataIn.data.campus,
        "buildings",
        dataIn.data.building,
        "rooms",
        dataIn.data.roomNumber
      )
    );
  };

  const handleChange = (event) => {
    setRefineRooms(event.target.value);
  };

  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  useEffect(() => {
    console.log(roomToDelete);
  }, [roomToDelete]);

  return (
    <ComponentOutterWrapper>
      <ComponentInnerWrapper>
        <LargeTitle>Rooms</LargeTitle>
        <HeaderWrapper>
          <ThemeProvider theme={theme}>
            <StyledButton
              bright
              callback={() => handleOpenAddRoom()}
              fontSize={"1.25rem"}
              height={"2.8rem"}
              width={"14rem"}>
              Add Room{" "}
              <DomainAdd style={{paddingLeft: "12px", height: "40px"}} />
            </StyledButton>
            <Box component="form" sx={{pl: 5}} noValidate autoComplete="off">
              <TextField
                sx={{width: "250px", fontSize: "22px"}}
                label="Search Rooms"
                variant="outlined"
                color="secondary"
                value={refineRooms}
                onChange={handleChange}
              />
            </Box>
          </ThemeProvider>
        </HeaderWrapper>
        <Table>
          <TableHeader>Room Name</TableHeader>
          <TableHeader>Room Attributes</TableHeader>
          <TableHeader>Campus</TableHeader>
          <TableHeader></TableHeader>
          {filteredRooms.length > 0 &&
            filteredRooms.map(
              (room, index) =>
                index <= numberOfRoomsToDisplay && (
                  <React.Fragment key={room.id}>
                    <div>
                      <Text>
                        {room.building
                          .toLowerCase()
                          .split("_")
                          .map(
                            (word) => word[0].toUpperCase() + word.substring(1)
                          )
                          .join(" ")}{" "}
                        {room.roomNumber}
                      </Text>
                    </div>
                    <div>
                      {room.computerLab && (
                        <Tooltip title="Computer Lab" arrow>
                          <ComputerIcon style={{color: "white"}} />
                        </Tooltip>
                      )}
                      {room.labAreasAttached.length > 0 && (
                        <Tooltip
                          title={`Lab for ${room.labAreasAttached.join(", ")}`}>
                          <BiotechIcon style={{color: "white"}} />
                        </Tooltip>
                      )}
                      {room.seats > 40 && (
                        <Tooltip title="Lecture over 40 seats">
                          <GroupsIcon style={{color: "white"}} />
                        </Tooltip>
                      )}
                    </div>
                    <div>
                      <Text>
                        {room.campus
                          .toLowerCase()
                          .split("_")
                          .map(
                            (word) => word[0].toUpperCase() + word.substring(1)
                          )
                          .join(" ")}
                      </Text>
                    </div>
                    <div>
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          paddingRight: "2vw",
                          color: "white",
                        }}
                        onClick={() => {
                          console.log(`Edit ${room.id}`);
                          setRoomDataToEdit(room);
                          handleOpenEditRoom();
                        }}
                      />
                      <DeleteForeverIcon
                        style={{cursor: "pointer", color: "white"}}
                        onClick={() => {
                          setRoomToDelete({
                            data: room,
                            label: `${room.building
                              .toLowerCase()
                              .split("_")
                              .map(
                                (word) =>
                                  word[0].toUpperCase() + word.substring(1)
                              )
                              .join(" ")} 
                        ${room.roomNumber}`,
                          });
                          handleOpenDelete();
                        }}
                      />
                    </div>
                  </React.Fragment>
                )
            )}
        </Table>
        {rooms.length === 0 && <LoadingLogoComp />}
        <Modal open={openAddRoom} onClose={handleCloseAddRoom}>
          <ModalBox>
            <ModalInner>
              <AddOrChangeRoom
                buttonText={"Add Room"}
                authHook={authHook}
                closeModal={handleCloseAddRoom}
                institution={props.institution}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <Modal open={openEditRoom} onClose={handleCloseEditRoom}>
          <ModalBox>
            <ModalInner>
              <AddOrChangeRoom
                buttonText={"Edit Room"}
                authHook={authHook}
                closeModal={handleCloseEditRoom}
                institution={props.institution}
                dataIn={roomDataToEdit}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <ConfirmAction
          dataForAction={roomToDelete}
          callback={(data) => removeRoom(data)}
          openState={openDelete}
          handleCloseState={() => handleCloseDelete()}>
          Delete
        </ConfirmAction>
        <FooterWrapper>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"17rem"}
            callback={() =>
              setNumberOfRoomsToDisplay(
                (numberOfInstructorsToDisplay) =>
                  numberOfInstructorsToDisplay + 20
              )
            }>
            Show More
          </StyledButton>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"17rem"}
            callback={() => props.setResourceComp("none")}>
            Done With Rooms
          </StyledButton>
        </FooterWrapper>
      </ComponentInnerWrapper>
    </ComponentOutterWrapper>
  );
};

export default Rooms;
