const daysOfWeekWithWeekend = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const minutesInHour = 60;

export const constructWeek = ({
  incrementMinIn,
  latestHour,
  firstHour,
  weekend,
}) => {
  let hoursArr = [];
  let minutesIncrement = incrementMinIn;
  for (let hourCurrent = firstHour; hourCurrent <= latestHour; hourCurrent++) {
    hoursArr.push(hourCurrent);
  }
  while (minutesInHour % minutesIncrement !== 0) {
    //not divisible by minute increment with no remainder, increment the increment
    console.log(
      `${minutesIncrement} minute increment cannot evenly divide the 60 minute hour, so we'll increment it by one.`
    );
    minutesIncrement++;
  }
  let minutesArr = [];
  const numberPortionsToDivideInto = minutesInHour / minutesIncrement;
  for (
    let hourSection = 1;
    hourSection <= numberPortionsToDivideInto;
    hourSection++
  ) {
    minutesArr.push(minutesIncrement * hourSection);
  }
  let weekObj = [];

  const getMinutesArr = () => {
    let minSpanArr = [];
    minutesArr.forEach((minuteMax) => {
      let initialMin;
      if (minuteMax === minutesIncrement) {
        initialMin = 0;
      } else {
        initialMin = minuteMax - minutesIncrement;
      }
      minSpanArr.push({initial: initialMin, final: minuteMax});
    });
    return minSpanArr;
  };

  const getHoursArr = () => {
    let hoursArrObjs = [];
    hoursArr.forEach((hour) => {
      let hourObj = {hour: hour};
      let minuteSpan = getMinutesArr();
      hourObj["minuteSpan"] = minuteSpan;
      hoursArrObjs.push(hourObj);
    });
    return hoursArrObjs;
  };

  const daysToUse = weekend ? daysOfWeekWithWeekend : daysOfWeek;
  daysToUse.forEach((day) => {
    let hours = getHoursArr();
    let dayObj = {day: day, hours: hours};
    weekObj.push(dayObj);
  });

  //Note the minutes will go to 60, which should be diplayed as zero with the hour incremented by 1
  return weekObj;
};
