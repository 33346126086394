import React from "react";
import { useAuth } from "../../services/use-auth";
import { updatePassword } from "@firebase/auth";

import AnimatedForm from "../../assets/forms";

const AccountPage = (props) => {
  const authHook = useAuth();

  const formInputsChangePW = [
    { name: "password1", label: "New Password", type: "password" },
    { name: "password2", label: "Confirm New Password", type: "password" },
  ];

  const submitButtonStyle = {
    width: "260px",
    fontSize: "1.5rem",
  };

  const submitChangePassword = (formInputs) => {
    const user = authHook.auth.currentUser;
    const newPassword = formInputs.password1;

    updatePassword(user, newPassword)
      .then(() => {
        console.log(`Password succesfully updated.`);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <AnimatedForm
      title={"Change Password"}
      formInputs={formInputsChangePW}
      submitButtonText={"Change Password"}
      submitFunction={submitChangePassword}
      buttonStyle={submitButtonStyle}
      displayBox={false}
    />
  );
};

export default AccountPage;
