import React, {useState} from "react";
import styled from "@emotion/styled";
import StyledButton from "../../assets/buttons";

import {useAuth} from "../../services/use-auth";
import {doc, setDoc} from "firebase/firestore";

import {ReactComponent as LoadingLogo} from "../../assets/logos/animated-logo-light.svg";
import {Descriptor, WrapperRow} from "./signup_flow_css";
import {SelectSubscriber} from "./setup_subscribe";
import {SelectAdmin} from "./setup_admin";

import EditIcon from "@mui/icons-material/Edit";

const Directions = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
`;
const WrapperVert = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperHoriz = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6vw;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`;

const ApplyingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  z-index: 3;
`;

const ApplyingLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  width: 20vw;
  @media only screen and (max-width: 800px) {
    width: 32vw;
  }
  @media only screen and (max-width: 650px) {
    width: 45vw;
  }
  @media only screen and (max-width: 450px) {
    width: 70vw;
  }
`;

const ApplyingText = styled.p`
  font-family: "MontserratMed";
  font-size: 42px;
  @media only screen and (max-width: 550px) {
    font-size: 28px;
  }
`;

const OnwerOnboardFlow = (props) => {
  //Owner will need to assign roles for subscriber and enrollment admin as "self" or to user with email address
  const authHook = useAuth();

  const [status, setStatus] = useState("Introduction");
  const [confirmedValues, setConfirmedValues] = useState({
    subscriberEmail: "",
    adminEmail: "",
  });

  const Introduction = () => {
    return (
      <WrapperVert>
        <Directions>
          You will now need to select users to take on the rolls of "Subscriber"
          and "Admin". These can also be set to "self" if you this is not a roll
          you want others responsible for.
        </Directions>
        <WrapperHoriz>
          <Descriptor>Subscriber</Descriptor>
          <Directions>
            This user will be responsible for paying the subscrition and
            mainting it. They should have access to a credit/debit card from
            your institution.
          </Directions>
        </WrapperHoriz>
        <WrapperHoriz>
          <Descriptor>Admin</Descriptor>
          <Directions>
            This user will be able to add faculty, courses, rooms and schedule
            classes. As "owner" you will still maintain the ability to do these
            things, but this would generally be someone you can delegate some of
            these tasks to.
          </Directions>
        </WrapperHoriz>
        <FooterWrapper>
          <StyledButton
            bright
            width={"300px"}
            fontSize={"24px"}
            callback={() => setStatus("Subscriber")}>
            Let's Get Started
          </StyledButton>
        </FooterWrapper>
      </WrapperVert>
    );
  };

  const Confirm = () => {
    const completeOnboard = () => {
      const newDocRef = doc(
        authHook.db,
        "users",
        props.authUser.uid,
        "institutionsRequested",
        props.userData.institution
      );
      setDoc(
        newDocRef,
        {
          adminEmail: confirmedValues.adminEmail,
          subscriberEmail: confirmedValues.subscriberEmail,
        },
        {merge: true}
      );
      setStatus("Applying");
    };

    return (
      <WrapperVert>
        <WrapperHoriz>
          <Descriptor>Confirm Your Choices</Descriptor>
        </WrapperHoriz>
        <WrapperRow>
          <Directions>
            Set your subscriber to: {confirmedValues.subscriberEmail}
          </Directions>
          <EditIcon
            style={{cursor: "pointer", marginLeft: "2vw"}}
            onClick={() => setStatus("Subscriber")}
          />
        </WrapperRow>
        <WrapperRow>
          <Directions>
            Set your admin to: {confirmedValues.adminEmail}
          </Directions>{" "}
          <EditIcon
            style={{cursor: "pointer", marginLeft: "2vw"}}
            onClick={() => setStatus("Admin")}
          />
        </WrapperRow>
        <FooterWrapper>
          <StyledButton
            bright
            width={"300px"}
            fontSize={"24px"}
            callback={completeOnboard}>
            Confirm My Choices
          </StyledButton>
        </FooterWrapper>
      </WrapperVert>
    );
  };

  return (
    <React.Fragment>
      {status === "Introduction" && <Introduction setStatus={setStatus} />}
      {status === "Subscriber" && (
        <SelectSubscriber
          setStatus={setStatus}
          setConfirmedValues={setConfirmedValues}
        />
      )}
      {status === "Admin" && (
        <SelectAdmin
          setStatus={setStatus}
          setConfirmedValues={setConfirmedValues}
        />
      )}
      {status === "Confirm" && <Confirm setStatus={setStatus} />}
      {status === "Applying" && (
        <ApplyingWrapper>
          <ApplyingLogoStyled />
          <ApplyingText>Applying Changes...</ApplyingText>
        </ApplyingWrapper>
      )}
    </React.Fragment>
  );
};

export default OnwerOnboardFlow;
