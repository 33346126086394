import React, {useState} from "react";
import CSVLoader from "./csvLoader";
import styled from "@emotion/styled";
import StyledButton from "../../assets/buttons";
import exImgCourse from "../../assets/images/coursesCSVExample.PNG";
import exImgFaculty from "../../assets/images/facultyCSVExample.PNG";
import exImgFascilities from "../../assets/images/roomCSVExample.PNG";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useAuth} from "../../services/use-auth";

import {doc, setDoc, updateDoc} from "firebase/firestore";
import {MenuItem} from "@mui/material";

const CopyText = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  width: 80vw;
`;

const TitleText = styled.p`
  font-size: 26px;
  font-family: "MontserratBold";
`;

const Example = styled.img`
  padding: 10px;
  padding-bottom: 30px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6vw;
  padding-left: 6vw;
  align-items: center;
`;

const InstitutionSetup = (props) => {
  const [step, setStep] = useState("courses");

  const authHook = useAuth();

  const headers = [
    "Course Name",
    "Course Code",
    "Course Number",
    "Lecture Hours",
    "Lab Hours",
    "Online Approved",
  ];

  const Courses = () => {
    const setData = (coursesArr) => {
      const createCourseFunc = (course) => {
        console.log("Creating course", course);
        const hasLab = course[headers[4]].length > 0;
        const labLheFloat = parseFloat(course[headers[4]]);
        const labRateInst = props.institution.labRate;
        const lectureLhe = parseFloat(course[headers[3]]);
        const courseCodeComplete = course[headers[1]]
          .toUpperCase()
          .concat("_")
          .concat(course[headers[2]]);
        let labLHE, labHours, onlineOK;
        if (!hasLab) {
          labLHE = 0;
          labHours = 0;
        }
        if (hasLab) {
          labLHE = Number((labLheFloat * labRateInst).toFixed(2));
          labHours = parseFloat(course[headers[4]]);
        }
        if (
          course[headers[5]] === undefined ||
          course[headers[5]] === "" ||
          course[headers[5]] === false ||
          course[headers[5]] === "No" ||
          course[headers[5]] === "NO" ||
          course[headers[5]] === "no" ||
          course[headers[5]] === "false" ||
          course[headers[5]] === "False" ||
          course[headers[5]] === "FALSE"
        ) {
          onlineOK = false;
        } else {
          onlineOK = true;
        }
        const newCourseRef = doc(
          authHook.db,
          "institutions",
          props.institution.docId,
          "courses",
          courseCodeComplete
        );
        let courseData = {
          name: course[headers[0]],
          area: course[headers[1]],
          courseNumber: course[headers[2]],
          lheLecture: lectureLhe,
          hoursLab: labHours,
          lheLab: labLHE,
          lheTotal: labLHE + lectureLhe,
          online: onlineOK,
        };

        console.log(courseData);
        setDoc(newCourseRef, courseData);
      };
      coursesArr.forEach((courseItem) => createCourseFunc(courseItem));
      setStep("faculty");
    };
    return (
      <React.Fragment>
        <TitleText>Courses</TitleText>
        <CopyText>
          We must first enter all courses from the course catalog that are
          offered. These will be used for all scheduling activities, but are
          tied to semester where this particular catalog course is approved to
          be taught. For this reason, we must first input the approved catalog
          of courses before intializing a particular semester to begin teaching.
          Please input <strong>all</strong> courses that are approved to be
          taught in the first semester you will be using Schedge.{" "}
        </CopyText>
        <CopyText>
          {" "}
          These can be entered one at a time, or they can all be entered at
          once. To enter all at once, upload a csv file with the headings:
          Course Name, Course Area Code, Course Number, Lecture Hours, Lab
          Hours, Online Approved."
        </CopyText>
        <CopyText>Please see the example for more information.</CopyText>
        <Example src={exImgCourse} />
        <CSVLoader
          setDataFunc={setData}
          headers={headers}
          confirmButtonText={"Courses"}
        />
      </React.Fragment>
    );
  };

  const Faculty = () => {
    const headers = [
      "Last Name",
      "First Name",
      "Email Address",
      "Areas of Instruction",
      "Online Certified",
      "Employment Status",
    ];
    const setData = (facultyArr) => {
      const createInstructor = (instructor) => {
        const newInstructorRef = doc(
          authHook.db,
          "institutions",
          props.institution.docId,
          "instructors",
          instructor[headers[2]]
        );
        let areas = instructor[headers[3]].replace(/\s+/g, "").split(";");
        let onlineCert;
        if (
          instructor[headers[4]] === undefined ||
          instructor[headers[4]] === "" ||
          instructor[headers[4]] === false ||
          instructor[headers[4]] === "No" ||
          instructor[headers[4]] === "NO" ||
          instructor[headers[4]] === "no" ||
          instructor[headers[4]] === "false" ||
          instructor[headers[4]] === "False" ||
          instructor[headers[4]] === "FALSE"
        ) {
          onlineCert = false;
        } else {
          onlineCert = true;
        }
        let instructorData = {
          firstName: instructor[headers[1]],
          lastName: instructor[headers[0]],
          fullName: instructor[headers[1]]
            .concat(" ")
            .concat(instructor[headers[0]]),
          email: instructor[headers[2]],
          onlineCert: onlineCert,
          areas: areas,
          fullTime: instructor[headers[5]] === "FT",
          partTime: instructor[headers[5]] === "PT",
        };
        console.log(instructorData);
        setDoc(newInstructorRef, instructorData);
      };
      facultyArr.forEach((facultyMember) => createInstructor(facultyMember));
      setStep("facilities");
    };
    return (
      <React.Fragment>
        <TitleText>Faculty</TitleText>
        <CopyText>
          We must next enter all faculty that are hired to teach. These faculty
          will be used for all scheduling activities in the first semester you
          use Schedge. This is why we must input all faculty before intializing
          a particular semester to begin teaching. Please input{" "}
          <strong>all</strong> faculty that are currently hired, and note that
          faculty can be added/removed later as needed.{" "}
        </CopyText>
        <CopyText>
          {" "}
          Faculty can be entered one at a time, or they can all be entered at
          once. To enter all at once, upload a csv file with the headings: Last
          Name, First Name, Email Address, Areas of Instruction, Online
          Certified and Employment Status."
        </CopyText>
        <CopyText>
          {" "}
          Email Address is <strong>very</strong> important to have correct,
          since this will link the user's account with this faculty member.
          <br />
          Areas of Instruction should be the codes for areas/departments, and
          multiple areas for one faculty should be seperated by{" "}
          <strong>semicolons</strong>.
          <br /> Employment Status is coded "FT" for Full Time or "PT" for part
          time (Adjunct).
        </CopyText>
        <CopyText>Please see the example for more information.</CopyText>
        <Example src={exImgFaculty} />
        <CSVLoader
          setDataFunc={setData}
          headers={headers}
          confirmButtonText={"Faculty"}
        />
      </React.Fragment>
    );
  };

  const Facilities = () => {
    const headers = [
      "Campus",
      "Building",
      "Room_Number",
      "Areas with Ownership",
      "Lab",
      "Computer Lab",
      "Seats",
    ];
    const setData = (roomsArr) => {
      const addRoom = (room) => {
        console.log("Adding room: ", room);
        const roomNumber = room[headers[2]].toString();
        const building = room[headers[1]].toUpperCase().replace(" ", "_");
        const campus = room[headers[0]].toUpperCase();
        const newRoomRef = doc(
          authHook.db,
          "institutions",
          props.institution.docId,
          "campus",
          campus,
          "buildings",
          building,
          "rooms",
          roomNumber
        );
        let labForArea, labAreasAttached, computerLab;

        if (
          room[headers[4]] === undefined ||
          room[headers[4]] === "" ||
          room[headers[4]] === false ||
          room[headers[4]] === "No" ||
          room[headers[4]] === "NO" ||
          room[headers[4]] === "no" ||
          room[headers[4]] === "false" ||
          room[headers[4]] === "False" ||
          room[headers[4]] === "FALSE"
        ) {
          labForArea = false;
          labAreasAttached = "false";
        } else {
          labForArea = true;
          labAreasAttached = room[headers[3]]
            .toUpperCase()
            .replace(/\s+/g, "")
            .split(";");
        }

        if (
          room[headers[5]] === undefined ||
          room[headers[5]] === "" ||
          room[headers[5]] === false ||
          room[headers[5]] === "No" ||
          room[headers[5]] === "NO" ||
          room[headers[5]] === "no" ||
          room[headers[5]] === "false" ||
          room[headers[5]] === "False" ||
          room[headers[5]] === "FALSE"
        ) {
          computerLab = false;
        } else {
          computerLab = true;
        }

        const areas = room[headers[3]].replace(/\s+/g, "").split(";");
        console.log(areas);
        let areasAsArr;
        if (areas.constructor === Array) {
          areasAsArr = areas;
        } else {
          areasAsArr = [areas];
        }

        let roomData = {
          campus: room[headers[0]],
          building: room[headers[1]],
          roomNumber: room[headers[2]],
          areasWithPermissions: areasAsArr,
          labForArea: labForArea,
          labAreasAttached: labAreasAttached,
          computerLab: computerLab,
          seats: parseInt(room[headers[6]]),
        };
        setDoc(newRoomRef, roomData);
      };
      roomsArr.forEach((room) => addRoom(room));
      setStep("catalog");
    };

    return (
      <React.Fragment>
        <TitleText>Facilities</TitleText>
        <CopyText>
          We must next enter all rooms that are available for instruction. These
          rooms will be used for all scheduling activities in the first semester
          you use Schedge. This is why we must input all rooms before
          intializing a particular semester to begin teaching. Please input{" "}
          <strong>all</strong> rooms that will be available for classes, and
          note that rooms can be added/removed/adjusted later as needed.{" "}
        </CopyText>
        <CopyText>
          {" "}
          Rooms can be entered one at a time, or they can all be entered all at
          one time. To enter all at once, upload a csv file with the headings:
          Campus, Building, Room Number, Areas with Ownership, Lab, Computer
          Lab, Seats"
        </CopyText>
        <CopyText>
          {" "}
          Areas of Ownership should be the codes for areas/departments that can
          teach classes in this room, and multiple areas for one room should be
          seperated by <strong>semicolons</strong>.
          <br /> Lab will set the class as a lab matching the areas of
          ownership. Any lab class must be scheduled in a corresponding lab room
          with the area.
        </CopyText>
        <CopyText>Please see the example for more information.</CopyText>
        <Example src={exImgFascilities} />
        <CSVLoader
          setDataFunc={setData}
          headers={headers}
          confirmButtonText={"Rooms"}
        />
      </React.Fragment>
    );
  };

  const Catalog = () => {
    const [values, setValues] = useState({year: "", semester: ""});
    const [entryErrors, setEntryErrors] = useState({
      year: false,
      semester: false,
    });
    const [entryFeedback, setEntryFeedback] = useState({
      year: null,
      semester: null,
    });

    const handleChange = (event) => {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    };

    const createSemester = () => {
      let semester = values.semester.concat("_").concat(values.year);
      const newCatRef = doc(
        authHook.db,
        "institutions",
        props.institution.docId,
        "catalogs",
        semester
      );

      setDoc(newCatRef, {initOn: Date.now()});
      setStep("completeAndLoading");
      const userDocRef = doc(authHook.db, "users", props.authUser.uid);
      updateDoc(userDocRef, {adminOnboard: true}, {merge: true});
      props.setShowLoading(true);
    };

    const validateYear = () => {
      if (values.year === "") {
        setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          year: "You must enter a year",
        }));
      } else {
        const yearInt = parseInt(values.year);
        const isValidNumber = !isNaN(yearInt);
        console.log(yearInt, isValidNumber);
        if (!isValidNumber) {
          setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            year: "You must enter a valid number for year",
          }));
        } else {
          if (yearInt < 2022) {
            setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
            setEntryFeedback((entryFeedback) => ({
              ...entryFeedback,
              year: "This year is already in the past",
            }));
          } else {
            if (yearInt > 2050) {
              setEntryErrors((entryErrors) => ({...entryErrors, year: true}));
              setEntryFeedback((entryFeedback) => ({
                ...entryFeedback,
                year: "This is a bit too far in the future...",
              }));
            } else {
              setEntryErrors((entryErrors) => ({...entryErrors, year: false}));
              setEntryFeedback((entryFeedback) => ({
                ...entryFeedback,
                year: null,
              }));
            }
          }
        }
      }
    };

    const semesters = [
      {value: "SPRING", label: "Spring"},
      {value: "FALL", label: "Fall"},
    ];

    const invalid = entryErrors.year || entryErrors.semester;

    return (
      <React.Fragment>
        <TitleText>Semester Catalog</TitleText>
        <CopyText>
          We must are now ready to initialize our first catalog! This will
          create availability lists for all rooms and faculty{" "}
          <strong>that have been added</strong>, so we can start to build
          schedules for the selected semester.
        </CopyText>
        <CopyText>
          {" "}
          Once this step is complete, you are ready to start scheduling classes!
        </CopyText>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            pb: 3,
            pl: 2,
            pr: 2,
          }}
          noValidate
          autoComplete="off">
          <TextField
            sx={{width: "350px", fontSize: "22px"}}
            label="Year"
            name="year"
            variant="outlined"
            color="secondary"
            value={values["year"]}
            onChange={handleChange}
            onBlur={() => validateYear()}
            error={entryErrors.year}
            helperText={entryFeedback.year}
          />
          <TextField
            sx={{width: "350px", fontSize: "22px"}}
            label="Semester"
            name="semester"
            color="secondary"
            select
            value={values["semester"]}
            onChange={handleChange}>
            {semesters.map((semesterOpt) => (
              <MenuItem key={semesterOpt.value} value={semesterOpt.value}>
                {semesterOpt.label}
              </MenuItem>
            ))}
          </TextField>
          <StyledButton
            disabled={invalid}
            primary
            callback={createSemester}
            width="250px"
            fontSize="26px">
            Initialize Semester!
          </StyledButton>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <ComponentWrapper>
      {step === "courses" && <Courses />}
      {step === "faculty" && <Faculty />}
      {step === "facilities" && <Facilities />}
      {step === "catalog" && <Catalog />}
    </ComponentWrapper>
  );
};

export default InstitutionSetup;
