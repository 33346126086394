import React from "react";
import styled from "@emotion/styled";
import {useAuth} from "../../services/use-auth";
import {doc, updateDoc} from "firebase/firestore";
import StyledButton from "../../assets/buttons";
import {FooterWrapper} from "./signup_flow_css";

const InstText = styled.p`
  font-family: "MontserratMed";
  font-size: 22px;
`;

const AcceptOnboard = (props) => {
  const auth = useAuth();
  const accept = async () => {
    const userRef = doc(auth.db, "users", props.authUser.uid);
    const response = await updateDoc(userRef, {onboarded: true});
    console.log(response);
  };
  return (
    <React.Fragment>
      <InstText>
        We found the Institution {props.userData.institutionName} for you, is
        this correct?
      </InstText>
      <FooterWrapper>
        <StyledButton
          bright
          width={"280px"}
          fontSize={"24px"}
          callback={accept}>
          Onboard Me!
        </StyledButton>
      </FooterWrapper>
    </React.Fragment>
  );
};

export default AcceptOnboard;
