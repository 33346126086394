import React from "react";
import AnimatedForm from "../../../assets/forms";
import {availabilityUtility} from "./availabilityCompiler/availabilityUtility";

const filterResourceForSelection = ({lectureSpanTimes, resources}) => {
  let instructorsInitialAvailability = [];
  resources.instructors.forEach((instructor) => {
    instructorsInitialAvailability.push(
      availabilityUtility({
        purpose: "instructors",
        element: instructor,
        spanNeeded: 0,
      })
    );
  });
  let classroomsInitialAvailability = [];
  resources.rooms.forEach((room) => {
    classroomsInitialAvailability.push(
      availabilityUtility({
        purpose: "rooms",
        element: room,
        spanNeeded: 0,
      })
    );
  });

  //TODO: Allow split rooms for lecture/labs
  // console.log(classroomsInitialAvailability);
  //Filter out classrooms who aren't available during selected lecture times
  let classroomsWithSelectedTimes;
  if (lectureSpanTimes.length > 0) {
    //Some lecture span times have been selected
    const arrOfLecturesSelectedClassroomAvail = [];
    //Build an array of available classrooms from each lecture time
    lectureSpanTimes.forEach((lectureTime) => {
      const classroomsWithSelectedTimeSingular =
        classroomsInitialAvailability.filter((classroom) => {
          let classroomFits;
          const thisDay = classroom.daysAvailability.filter(
            (day) => day.day === lectureTime.day
          )[0];
          if (thisDay && thisDay.availableAllDay) {
            classroomFits = true;
          } else {
            //Check if this portential lecture fits the availability of classroom.
            const intervalForHour = thisDay.availabilityIntervals.filter(
              (interval) =>
                interval.initialHour <= lectureTime.hourInit &&
                interval.finalHour >= lectureTime.hourFinal
            )[0];
            if (intervalForHour !== undefined) {
              //Check the minutes to see if it truly fits
              classroomFits =
                (intervalForHour.initialHour < lectureTime.hourInit ||
                  (intervalForHour.initialHour === lectureTime.hourInit &&
                    intervalForHour.initialMinute < lectureTime.minuteInit)) &&
                (intervalForHour.finalHour > lectureTime.hourFinal ||
                  (intervalForHour.finalHour === lectureTime.hourFinal &&
                    intervalForHour.initialMinute > lectureTime.finalMinute));
            } else {
              classroomFits = false;
            }
          }
          return classroomFits;
        });
      console.log(classroomsWithSelectedTimeSingular);
      arrOfLecturesSelectedClassroomAvail.push(
        classroomsWithSelectedTimeSingular
      );
    });
    // console.log(arrOfLecturesSelectedClassroomAvail);
    classroomsWithSelectedTimes = classroomsInitialAvailability.filter(
      //Return classrooms who are available at all lecture times
      (classroom) => {
        //Check to see if the classroom is available at all lecture times
        const thisClassroomAvailAllTimesSelected =
          arrOfLecturesSelectedClassroomAvail.every((classroomsAvail) => {
            //within the array of lecture times selected now
            const classroomFilteredArr = classroomsAvail.filter(
              (classroomSingularAvail) =>
                classroomSingularAvail.room === classroom.room
            );
            // console.log(classroom);
            // console.log(classroomFilteredArr);
            //There will be an classroom in this array if this classroom is available at this lecture time
            return classroomFilteredArr.length > 0;
          });
        return thisClassroomAvailAllTimesSelected;
      }
    );
  } else {
    classroomsWithSelectedTimes = classroomsInitialAvailability;
  }

  //Filter out instructors who aren't available during selected lecture times
  let instructorsWithSelectedTimes;
  if (lectureSpanTimes.length > 0) {
    //Some lecture span times have been selected
    const arrOfLecturesSelectedInstructorAvail = [];
    //Build an array of available instructors from each lecture time
    lectureSpanTimes.forEach((lectureTime) => {
      const instructorsWithSelectedTimeSingular =
        instructorsInitialAvailability.filter((instructor) => {
          let instructorFits;
          const thisDay = instructor.daysAvailability.filter(
            (day) => day.day === lectureTime.day
          )[0];
          if (thisDay && thisDay.availableAllDay) {
            instructorFits = true;
          } else {
            //Check if this portential lecture fits the availability of instructor.
            const intervalForHour = thisDay.availabilityIntervals.filter(
              (interval) =>
                interval.initialHour <= lectureTime.hourInit &&
                interval.finalHour >= lectureTime.hourFinal
            )[0];
            if (intervalForHour !== undefined) {
              //Check the minutes to see if it truly fits
              console.log(intervalForHour);
              console.log(lectureTime);
              instructorFits =
                (intervalForHour.initialHour < lectureTime.hourInit ||
                  (intervalForHour.initialHour === lectureTime.hourInit &&
                    intervalForHour.initialMinute < lectureTime.minuteInit)) &&
                (intervalForHour.finalHour > lectureTime.hourFinal ||
                  (intervalForHour.finalHour === lectureTime.hourFinal &&
                    intervalForHour.initialMinute > lectureTime.finalMinute));
            } else {
              instructorFits = false;
            }
          }
          return instructorFits;
        });
      arrOfLecturesSelectedInstructorAvail.push(
        instructorsWithSelectedTimeSingular
      );
    });
    // console.log(arrOfLecturesSelectedInstructorAvail);
    instructorsWithSelectedTimes = instructorsInitialAvailability.filter(
      //Return instructors who are available at all lecture times
      (instructor) => {
        //Check to see if the instructor is available at all lecture times
        const thisInstructorAvailAllTimesSelected =
          arrOfLecturesSelectedInstructorAvail.every((instructorsAvail) => {
            //within the array of lecture times selected now
            const instructorFilteredArr = instructorsAvail.filter(
              (instructorSingularAvail) =>
                instructorSingularAvail.instructor === instructor.instructor
            );
            console.log(instructorFilteredArr);
            //There will be an instructor in this array if this instructor is available at this lecture time
            return instructorFilteredArr.length > 0;
          });
        return thisInstructorAvailAllTimesSelected;
      }
    );
  } else {
    instructorsWithSelectedTimes = instructorsInitialAvailability;
  }

  const roomsList = [];
  classroomsWithSelectedTimes.forEach((classroom) =>
    roomsList.push(classroom.room)
  );

  const instructorsList = [];
  instructorsWithSelectedTimes.forEach((instructor) => {
    console.log(instructor);
    instructorsList.push(instructor.instructor);
  });
  return {roomsList: roomsList, instructorsList: instructorsList};
};

const FinalizeAddClass = (props) => {
  const availList = filterResourceForSelection({
    lectureSpanTimes: props.lectureSpanTimes,
    resources: props.options,
  });
  console.log(availList);

  const instructors = [];
  props.options.instructors.forEach((instructor) => {
    const instructorAvail = availList.instructorsList.some(
      (instrucAvail) => instrucAvail === instructor.instructorID
    );
    if (instructorAvail) {
      instructors.push({
        value: instructor.instructorID,
        label: instructor.instructorName,
      });
    }
  });

  const rooms = [];
  props.options.rooms.forEach((room) => {
    const roomAvail = availList.roomsList.some(
      (roomAvail) => roomAvail === room.roomName
    );

    if (roomAvail) {
      rooms.push({
        value: room.roomName,
        label: room.roomName.concat(` (${room.seats} seats)`),
      });
    }
  });

  const formInputs = [
    {
      name: "instructor",
      label: "Instructor",
      type: "radio",
      options: instructors,
    },
    {name: "classroom", label: "Room", type: "radio", options: rooms},
  ];

  const submitButtonStyle = {width: "260px", fontSize: "1.5rem"};

  return (
    <React.Fragment>
      <AnimatedForm
        title={"Create New Class"}
        formInputs={formInputs}
        submitButtonText={"Create!"}
        submitFunction={props.registerClass}
        buttonStyle={submitButtonStyle}
        disableBox={true}
      />
    </React.Fragment>
  );
};

export default FinalizeAddClass;
