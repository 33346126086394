import React, {useState, useEffect} from "react";
import styled from "@emotion/styled";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
`;

export const ConfirmAction = ({
  callback,
  children,
  openState,
  handleCloseState,
  dataForAction,
}) => {
  const [value, setValue] = useState(30);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value >= 95) {
      handleCloseState();
      callback(dataForAction);
      setValue(30);
    }
  }, [callback, handleCloseState, value]);

  return (
    <Modal open={openState} onClose={handleCloseState}>
      <ModalBox>
        {dataForAction !== undefined && (
          <ModalInner>
            <Text>
              Slide to {children} {dataForAction.label}
            </Text>
            <Slider
              style={{color: "#A72815"}}
              aria-label="Confirm"
              value={value}
              onChange={handleChange}
            />
          </ModalInner>
        )}
      </ModalBox>
    </Modal>
  );
};
