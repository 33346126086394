import styled from "@emotion/styled";
import {ReactComponent as LoadingLogo} from "../../assets/logos/animated-logo.svg";

export const Title = styled.p`
  font-family: "MontserratMed";
  font-size: 18px;
  color: white;
`;

export const SearchWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const SearchLogoStyled = styled(LoadingLogo)`
  z-index: 5;
  transform: translateY(-15vh);
  width: 20vw;
  @media only screen and (max-width: 800px) {
    width: 32vw;
  }
  @media only screen and (max-width: 650px) {
    width: 45vw;
  }
  @media only screen and (max-width: 450px) {
    width: 70vw;
  }
`;

export const SearchText = styled.p`
  font-family: "MontserratMed";
  font-size: 42px;
  @media only screen and (max-width: 550px) {
    font-size: 28px;
  }
`;

export const OnboardingInnerWrapper = styled.div`
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 5vh;
  padding-top: 2vh;
  border-radius: 8px;
  background-image: linear-gradient(to right, #1e242b, black);
`;

export const OnboardingWrapper = styled.div`
  margin-right: 20vw;
  margin-left: 20vw;
  background-color: #cccccc;

  //Code to make a border gradient
  border: double 6px transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    radial-gradient(
      circle at top left,
      #b22418,
      #f19e38,
      #94cd3f,
      #57aff6,
      #d02c85,
      #56193f
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const Descriptor = styled.p`
  font-size: 18px;
  font-family: "MontserratBold";
  width: 220px;
  color: white;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`;

export const Directions = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
`;
export const WrapperVert = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperHoriz = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6vw;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 6vw;
  align-items: center;
`;
