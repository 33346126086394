//Function for getting each element's availability - element being instructor or classroom
export const availabilityUtility = ({ purpose, element, spanNeeded }) => {
  let daysAvailability = [];
  element.availability.forEach((day) => {
    let availableAllDay = day.hours.every(
      (hour) => !hour.fullyTaken && !hour.partiallyTaken
    );
    let notAvailableAllDay = day.hours.every((hour) => hour.fullyTaken);

    //Run this if there is some partial availability for the day to compile intervals of availability for one instructor
    if (!availableAllDay && !notAvailableAllDay) {
      const freeHourIntervals = [];

      //Use filter functions to build lists of hours that are taken and hours that are free
      let takenHours = day.hours.filter(
        (hour) => hour.partiallyTaken || hour.fullyTaken
      );
      let freeHours = day.hours.filter((hour) =>
        takenHours.every((hourTaken) => hourTaken !== hour)
      );

      //Build a list of hour intervals where the instructor is available
      let i = 0;
      let lastHour = freeHours[i].hour;
      let arrLength = freeHours.length;
      let finalHourInArr = freeHours[arrLength - 1].hour;
      //Loop through free hours using nested while loops to find hours that are consecutively free (unbroken interval)

      while (lastHour < finalHourInArr) {
        //j here allows us to track how many hours we've added to the first hour within an interval
        //i allows us to select the next hour in the array of free hours (may not be consecutive)
        let j = 0;
        let nextHour;
        let firstHour = freeHours[i].hour;
        do {
          i++;
          j++;
          nextHour = freeHours[i].hour;
          //Testing
          // if(element.instructorID === "teresam@cos.edu" && day.day === "Monday" && j === 2){
          // console.log(
          //   `firstHour: ${firstHour}, nextHour: ${nextHour}, day: ${day.day}, j:${j}`
          // );
          // }
          // console.log(nextHour);
        } while (firstHour + j === nextHour && nextHour !== finalHourInArr);

        let isFinalHour = false;
        let finalHour;
        let initialMinuteTaken = 0,
          finalMinuteTaken = 0;
        //if j === 1, this hour is an island, there is only one available hour, so we cannot go back one hour
        if (nextHour !== finalHourInArr && j !== 1) {
          //Go back one since the current "nextHour" failed the while test, meaning the last one was the one we want, unless it's the first since this
          // means there is only one available hour in between. It must double as the first and the last

          let finalHourFromFree =
            j === 0 ? freeHours[i].hour : freeHours[i - 1].hour;

          let hourAfterFinal = takenHours.filter(
            (hourItem) => hourItem.hour === finalHourFromFree + 1
          )[0];
          //If this is undefined, there may not be an hour after the final.
          //Check if the next hour is partially taken, meaning this class spans into it?
          // if (day.day === "Monday") {
          //   console.log(`Day: ${day.day}`);
          //   console.log(`J: ${j}`);
          //   console.log(day.hours);
          //   console.log(takenHours);
          //   console.log(freeHours);
          //   console.log(`Final hour from free: ${finalHourFromFree}`);
          //   console.log(`---------------------------`);
          // }

          let firstRangeInHourAfterFinal = hourAfterFinal.minuteRangesTaken[0];
          let initialMinTaken = firstRangeInHourAfterFinal.split("-")[0];
          let finalMinTakenInitialArr =
            firstRangeInHourAfterFinal.split("-")[1];
          if (initialMinTaken === 0) {
            finalHour = freeHours[i - 1].hour;
          } else if (initialMinTaken !== 0) {
            finalHour = hourAfterFinal.hour;
            finalMinuteTaken = initialMinTaken;
          }
        } else if (nextHour !== finalHourInArr && j === 1) {
          finalHour = firstHour;
          let hourAfterFinal = takenHours.filter(
            (hourItem) => hourItem.hour === finalHour + 1
          )[0];
          let firstRangeInHourAfterFinal = hourAfterFinal.minuteRangesTaken[0];
          let initialMinTaken = firstRangeInHourAfterFinal.split("-")[0];
          finalMinuteTaken = initialMinTaken;
        } else {
          finalHour = nextHour;
          isFinalHour = true;
        }

        //Store the hour interval, we'll use first and last hours to look up minute availability later
        let hourInterval = { initialHour: firstHour, finalHour: finalHour };
        let hourBeforeFirstFull = firstHour - 1;

        //Check here for partially taken hours, we'll use the partially taken hour objects to find the final/intial minutes
        let initialHourIfPartiallyTakenArr = takenHours.filter(
          (takenHourRaw) =>
            hourBeforeFirstFull === takenHourRaw.hour &&
            takenHourRaw.partiallyTaken
        );
        let finalHourIfPartiallyTaken = takenHours.filter(
          (takenHourRaw) =>
            finalHour === takenHourRaw.hour && takenHourRaw.partiallyTaken
        );

        //If the hour before our initial hour was partially taken, that's the real start time, so find the last minute taken in that hour
        if (initialHourIfPartiallyTakenArr.length > 0) {
          let initialPartiallyTakenHour = initialHourIfPartiallyTakenArr[0];
          let minuteRangesInArr =
            initialPartiallyTakenHour.minuteRangesTaken.length;
          let lastMinArr =
            initialPartiallyTakenHour.minuteRangesTaken[minuteRangesInArr - 1];
          initialMinuteTaken = lastMinArr.split("-")[1];
          if (day.day === "Monday") {
            // console.log(
            //   `Initial minute taken: ${initialMinuteTaken} for ${initialPartiallyTakenHour.hour}`
            // );
          }
          if (initialMinuteTaken !== "60") {
            hourInterval["initialHour"]--;
          } else {
            hourInterval["finalHour"]++;
            initialMinuteTaken = 0;
          }
        }
        //Possibly Delete the following:
        //If the final hour was partially taken, we need to find the first minute taken in its first interval as that is the first minute we have in that hour
        if (isFinalHour && finalHourIfPartiallyTaken.length > 0) {
          let finalPartiallyTakenHour = finalHourIfPartiallyTaken[0];
          let firstMinArr = finalPartiallyTakenHour.minuteRangesTaken;
          finalMinuteTaken = firstMinArr[0].split("-")[0];
        } else if (isFinalHour) {
          finalMinuteTaken = 60;
        }

        hourInterval["initialMinute"] = initialMinuteTaken;
        hourInterval["finalMinute"] = parseInt(finalMinuteTaken);

        //Here refine actual availability depending on (1)lecture timespan fitting available timespan
        let freeTimeSpanInMinutes =
          (hourInterval["finalHour"] - hourInterval["initialHour"]) * 60;
        freeTimeSpanInMinutes =
          freeTimeSpanInMinutes +
          (hourInterval["finalMinute"] - hourInterval["initialMinute"]);
        let spanFits = freeTimeSpanInMinutes >= spanNeeded;
        if (spanFits) {
          freeHourIntervals.push(hourInterval);
        } else {
          // console.log(
          //   `Skipping because it doesn't fit the class time span: ${hourInterval["initialHour"]}:${hourInterval["initialMinute"]} - ${hourInterval["finalHour"]}:${hourInterval["finalMinute"]}`
          // );
        }

        lastHour = nextHour;
      }
      daysAvailability.push({
        day: day.day,
        availableAllDay: false,
        availabilityIntervals: freeHourIntervals,
      });
    } else {
      //Resource was free all day
      daysAvailability.push({ day: day.day, availableAllDay: true });
    }
  });
  //This will be pushed upon return into the array of either rooms or instructors
  switch (purpose) {
    case "instructors":
      return {
        instructor: element.instructorID,
        daysAvailability: daysAvailability,
      };

    case "rooms":
      return { room: element.roomName, daysAvailability: daysAvailability };

    default:
      console.error(
        `Failed to determine the purpose, please check the "getAvailability" method`
      );
      break;
  }
};
