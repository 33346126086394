import React, {useState, useEffect, useReducer} from "react";
import styled from "styled-components";
import {constructWeek} from "../../../../assets/timeSlots";
import {DayLabel, HourLabel, HourDiv, DayDiv} from "./grid_styles";

const MinuteDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 4px;
  cursor: pointer;
  ${(props) =>
    props.fillDivUnavail || props.hoverUnavail
      ? props.fillDivHover
        ? "background-color: black; cursor: not-allowed;"
        : "background-color: #D3D3D3;"
      : props.fillDivHover
      ? props.cancelHoverState
        ? ""
        : "background-image: linear-gradient(to right, #ee3189, #f36d21);"
      : props.fillDivSelected
      ? "background-image: linear-gradient(to right, #469dd7, #70c8b8);"
      : ""}
  /* opacity: ${(props) =>
    props.fillDivHover ? 80 : props.fillDivSelected ? 80 : 0}; */
  
  
  pointer-events: all;
  z-index: 1;

  :hover {
    opacity: 80;
  }
`;

const WeekDiv = styled.div`
  display: flex;
  flex-direction: row;
  pointer-events: none;
`;

const SpansContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: none;
`;

//This function sets up a time span (as an object) for div highlighting for a lecture time, used mostly for hovering
const reducer = (state, spanInfo) => {
  let day = spanInfo.day;
  let hour = spanInfo.hour;
  let firstMin = spanInfo.initMin;
  let minuteSpan = spanInfo.lectureTimeSpan;
  let classTimes = {};
  if (minuteSpan + firstMin >= 60) {
    //Triggers if it crosses the hour mark
    //Build an array of hours marked
    let hourSpan = Math.trunc((minuteSpan + firstMin) / 60);
    let minutesLeft = (minuteSpan + firstMin) % 60;
    let hourLast = hour + hourSpan;
    let minuteLast = minutesLeft;
    classTimes = {
      day: day,
      hourInit: hour,
      hourFinal: hourLast,
      minuteInit: firstMin,
      minuteFinal: minuteLast,
      lessThanHour: false,
    };
  } else {
    //Triggers if it doesn't cross the hour mark
    classTimes = {
      day: day,
      hourInit: hour,
      hourFinal: hour,
      minuteInit: firstMin,
      minuteFinal: firstMin + minuteSpan,
      lessThanHour: true,
    };
  }
  spanInfo.setClassTimeHovering(classTimes);
  return classTimes;
};

const WeekComp = (props) => {
  const [timeSpan, dispatch] = useReducer(reducer, {});
  const [cancelHover, setCancelHover] = useState(false);

  const weekSettings = {
    incrementMinIn: 5,
    latestHour: 21,
    firstHour: 7,
    weekend: false,
  };

  let weekObj = constructWeek(weekSettings);

  const controlHover = (input) => {
    setCancelHover(input);
  };

  useEffect(() => {
    //Need to reset timeSpan to initial state of empty object here
  }, [props.spanTime]);

  const convertHour = (hour24, noLetters) => {
    let hour12 = hour24 % 12;
    if (hour12 === 0) {
      hour12 = 12;
    }
    return noLetters
      ? hour12.toString()
      : `${hour12} ${hour24 < 12 ? "AM" : "PM"}`;
  };

  //This function is to convert minutes to 2 digits for times like 7:05
  const convertMinute = (minute) => {
    return minute.toString().padStart(2, "0");
  };

  //This function checks a timespan to see if the div in question's day/hour/minute matches an input object's day/hour/minute
  const checkTimeSpan = ({actualClasstimesObject, divTimesObject}) => {
    const runCheck = (classTime) => {
      // Check if the div day is the same as the class day
      const inDay = classTime.day === divTimesObject.day;

      // Check if the div hour is between the class start and end hours, or if the div hour is the same as the class end hour and the div minute is before the class end minute
      const inHour =
        (classTime.hourInit <= divTimesObject.hour &&
          divTimesObject.hour < classTime.hourFinal) ||
        (classTime.hourFinal === divTimesObject.hour &&
          divTimesObject.minuteInit < classTime.minuteFinal);

      // Check if the div minute is between the class start and end minutes, or if the div hour is the same as the class start hour and the div minute is after the class start minute
      const inMinute =
        (classTime.hourInit === divTimesObject.hour &&
          divTimesObject.minuteInit >= classTime.minuteInit) ||
        (classTime.hourFinal === divTimesObject.hour &&
          divTimesObject.minuteInit < classTime.minuteFinal) ||
        (classTime.hourInit < divTimesObject.hour &&
          classTime.hourFinal > divTimesObject.hour);

      // Return true if all conditions are true, false otherwise
      return inDay && inHour && inMinute;
    };

    if (actualClasstimesObject.length > 0) {
      //Do the same action as the single but for an array
      let arrOfBools = [];
      actualClasstimesObject.forEach((classTime) => {
        //If it's in the second hour, minutes must be counted differently
        arrOfBools.push(runCheck(classTime));
      });
      //Check if any of them are true, ie any of the class times contain this div
      let result = arrOfBools.some((x) => x);
      return result;
    } else {
      //If it's in the second hour, minutes must be counted differently
      return runCheck(actualClasstimesObject);
    }
  };

  //This function checks if the hour of the input div contains less than the first half of the hour, in order to render the
  //class's time label for the span in the more central div for a class timespan
  const whichLecture = ({LecturesArr, divTime}) => {
    //Take the arr, determine which one, then return a string to render the span
    let spans = [];
    LecturesArr.forEach((lectureObj) => {
      const isDay = lectureObj.day === divTime.day;
      let isAnHour;
      if (lectureObj.minuteInit > 34) {
        isAnHour = lectureObj.hourInit + 1 === divTime.hour;
      } else {
        isAnHour = lectureObj.hourInit === divTime.hour;
      }
      if (isDay && isAnHour) {
        let span = `${convertHour(lectureObj.hourInit, true)}:${convertMinute(
          lectureObj.minuteInit
        )} - ${convertHour(lectureObj.hourFinal, true)}:${convertMinute(
          lectureObj.minuteFinal
        )}`;
        spans.push(span);
      }
    });
    return spans[0];
  };

  const checkAvail = ({availDataIn, divTimesObject}) => {
    const dayObj = availDataIn.filter(
      (day) => day.day === divTimesObject.day
    )[0];
    if (dayObj.availableAllDay) {
      return true;
    } else {
      const hourObj = dayObj.hours.filter(
        (hour) => hour.hour === divTimesObject.hour
      )[0];
      if (hourObj !== undefined && hourObj.fullyAvailable) {
        return true;
      } else if (hourObj !== undefined) {
        return (
          (divTimesObject.minuteInit > hourObj.initialMinuteAvail ||
            (divTimesObject.minuteInit === 0 &&
              hourObj.initialMinuteAvail === 0)) &&
          (divTimesObject.minuteFinal < hourObj.finalMinuteAvailable ||
            (divTimesObject.minuteFinal === 60 &&
              hourObj.finalMinuteAvailable === 60))
        );
      } else {
        return false;
      }
    }
  };

  const clickedMinuteDiv = () => {
    //Don't allow this to work if there's a conflict with availability
    let itFits;
    const lecDay = timeSpan.day;
    const lecHourInit = timeSpan.hourInit;
    const lecHourFinal = timeSpan.hourFinal;
    const lecMinuteInit = timeSpan.minuteInit;
    const lecMinuteFinal = timeSpan.minuteFinal;
    const day =
      props.refinedCompiledAvail.length > 0
        ? props.refinedCompiledAvail.find((d) => d.day === lecDay)
        : props.availResourcesCompiled.find((d) => d.day === lecDay);
    //Check if the entire day is open, since there will then not be any hours here
    console.log(day);
    if (day.availableAllDay) {
      itFits = true;
    } else {
      const hoursAvailInLecSpan = day.hours.filter((hour) => {
        let thisHourAvail =
          hour.hour >= lecHourInit && hour.hour <= lecHourFinal;
        return thisHourAvail;
      });
      console.log(hoursAvailInLecSpan);
      let totallyOpen = hoursAvailInLecSpan.every((hour) => {
        return hour.fullyAvailable;
      });
      if (!totallyOpen) {
        itFits = hoursAvailInLecSpan.every((hour, index) => {
          if (index === 0) {
            //This is the first hour
            return (
              hour.fullyAvailable ||
              (hour.initialMinuteAvail < lecMinuteInit &&
                hour.finalMinuteAvailable > lecMinuteFinal)
            );
          } else if (index === hoursAvailInLecSpan.length - 1) {
            //This is the last hour
            return (
              hour.fullyAvailable ||
              hour.finalMinuteAvailable >= lecMinuteFinal ||
              lecMinuteFinal === 0
            );
          } else {
            //This is a middle hour if there is one
            return hour.fullyAvailable;
          }
        });
      } else {
        itFits = true;
      }
    }
    console.log(`It fits: ${itFits}`);
    if (itFits) {
      props.clickedATime(timeSpan);
    } else {
      console.error("This is unavailable - I guess not really an error?");
    }
  };

  useEffect(() => {
    console.log(timeSpan);
  }, [timeSpan]);

  return (
    <React.Fragment>
      {props.availResourcesCompiled &&
        props.availResourcesCompiled.length > 0 && (
          <WeekDiv
            onMouseLeave={() => controlHover(true)}
            onMouseEnter={() => controlHover(false)}>
            {weekObj.map((day) => (
              <DayDiv key={day.day.concat("Div")}>
                <DayLabel key={day.day.concat("Label")}>{day.day}</DayLabel>

                {day.hours.map((hour) => (
                  <HourDiv key={day.day.concat(hour.hour).concat("Div")}>
                    <SpansContainer
                      key={day.day.concat(hour.hour).concat("Container")}>
                      <HourLabel
                        key={day.day.concat(hour.hour).concat("Label")}>
                        {/* Change this to class span label if the course falls here */}
                        {day.day === timeSpan.day &&
                        hour.hour >= timeSpan.hourInit &&
                        hour.hour < timeSpan.hourFinal &&
                        !cancelHover
                          ? !timeSpan.lessThanHour
                            ? whichLecture({
                                LecturesArr: [
                                  {
                                    minuteInit: timeSpan.minuteInit,
                                    minuteFinal: timeSpan.minuteFinal,
                                    hourInit: timeSpan.hourInit,
                                    hourFinal: timeSpan.hourFinal,
                                    day: timeSpan.day,
                                  },
                                ],
                                divTime: {day: day.day, hour: hour.hour},
                              })
                            : `${convertHour(
                                timeSpan.hourInit,
                                true
                              )}:${convertMinute(
                                timeSpan.minuteInit
                              )} - ${convertHour(
                                timeSpan.hourFinal,
                                true
                              )}:${convertMinute(timeSpan.minuteFinal)}`
                          : checkTimeSpan({
                              actualClasstimesObject: props.selectedLectures,
                              divTimesObject: {
                                day: day.day,
                                hour: hour.hour,
                                lectureTimeSpan: props.spanTime,
                              },
                            })
                          ? whichLecture({
                              LecturesArr: props.selectedLectures,
                              divTime: {day: day.day, hour: hour.hour},
                            })
                          : convertHour(hour.hour)}
                      </HourLabel>

                      {hour.minuteSpan.map((spanObj) => (
                        <MinuteDiv
                          key={day.day
                            .concat(hour.hour)
                            .concat(spanObj.initial)
                            .concat("Div")}
                          onClick={() => clickedMinuteDiv()}
                          onMouseEnter={() => {
                            dispatch({
                              day: day.day,
                              hour: hour.hour,
                              initMin: spanObj.initial,
                              lectureTimeSpan: props.spanTime,
                              setClassTimeHovering: props.setClassTimeHovering,
                            });
                          }}
                          fillDivHover={checkTimeSpan({
                            actualClasstimesObject: timeSpan,
                            divTimesObject: {
                              day: day.day,
                              hour: hour.hour,
                              minuteInit: spanObj.initial,
                              minuteFinal:
                                spanObj.initial + weekSettings.incrementMinIn,
                              lectureTimeSpan: props.spanTime,
                            },
                          })}
                          fillDivSelected={checkTimeSpan({
                            actualClasstimesObject: props.selectedLectures,
                            divTimesObject: {
                              day: day.day,
                              hour: hour.hour,
                              minuteInit: spanObj.initial,
                              minuteFinal:
                                spanObj.initial + weekSettings.incrementMinIn,
                            },
                          })}
                          fillDivUnavail={
                            !checkAvail({
                              availDataIn: props.availResourcesCompiled,
                              divTimesObject: {
                                day: day.day,
                                hour: hour.hour,
                                minuteInit: spanObj.initial,
                                minuteFinal:
                                  spanObj.initial + weekSettings.incrementMinIn,
                                lectureTimeSpan: props.spanTime,
                              },
                            })
                          }
                          hoverUnavail={
                            props.refinedCompiledAvail
                              ? !checkAvail({
                                  availDataIn: props.refinedCompiledAvail,
                                  divTimesObject: {
                                    day: day.day,
                                    hour: hour.hour,
                                    minuteInit: spanObj.initial,
                                    minuteFinal:
                                      spanObj.initial +
                                      weekSettings.incrementMinIn,
                                    lectureTimeSpan: props.spanTime,
                                  },
                                })
                              : false
                          }
                          cancelHoverState={cancelHover}
                        />
                      ))}
                    </SpansContainer>
                  </HourDiv>
                ))}
              </DayDiv>
            ))}
          </WeekDiv>
        )}
    </React.Fragment>
  );
};

export default WeekComp;
