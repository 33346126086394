import { availabilityUtility } from "./availabilityUtility";
import { compiler } from "./getCompilation";

const hoursConst = { firstHour: 7, lastHour: 21 };

export const getAvailability = ({
  resources,
  spanNeeded,
  requirements,
  selectedOptions,
  setShowLoading,
  step,
}) => {
  console.log(`Run Func => Get Availability`);
  console.log(resources);

    //When the calling function GUI selects a time, we must refine availability to match the only rooms/instructors available during this time for the
    //rest of the time/instructor options.
    let lectureSpansSelected = selectedOptions.classSpanTimes.filter(
      (span) => span["lecture"]
    );
    let labSpansSelected = selectedOptions.classSpanTimes.filter(
      (span) => span["lab"]
    );
    console.log(lectureSpansSelected);
    console.log(`Requirements: ${requirements}`);

    let roomResources;
  if(requirements){
    roomResources = resources.rooms.filter(room => room.labTag === requirements);
  } else {
    roomResources = resources.rooms;
  }

  //Instructor Availability Getter
  let instructorsAvailability = [];
  resources.instructors.forEach((instructor) => {
    instructorsAvailability.push(
      availabilityUtility({
        purpose: "instructors",
        element: instructor,
        spanNeeded: spanNeeded,
      })
    );
  });

  //Classroom Availability Getter
  let classroomAvailability = [];
  roomResources.forEach((room) => {
    classroomAvailability.push(
      availabilityUtility({
        purpose: "rooms",
        element: room,
        spanNeeded,
        spanNeeded: spanNeeded,
      })
    );
  });

  // //Log Availability
  // console.log(`Insturctor Availability:`);
  // console.log(instructorsAvailability);
  // console.log(`Classroom Availability`);
  // console.log(classroomAvailability);

  //Get Institutional default week structure
  const defaultWeekTemplate = instructorsAvailability[0].daysAvailability;
  let defaultWeek = [];
  for (let i = 0; i < defaultWeekTemplate.length; i++) {
    const day = defaultWeekTemplate[i];
    const hoursInDay = [];
    for (let j = 0; j + hoursConst.firstHour <= hoursConst.lastHour; j++) {
      hoursInDay.push({ hour: j + hoursConst.firstHour });
    }
    defaultWeek.push({ day: day.day, hours: hoursInDay });
  }

  // console.log(`Default Week`);
  // console.log(defaultWeek);

  const availabilityCompiled = compiler({
    defaultWeek: defaultWeek,
    instructorsAvailability: instructorsAvailability,
    classroomAvailability: classroomAvailability,
  });
  setShowLoading(false);
  return availabilityCompiled;
};
