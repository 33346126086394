import React, {useEffect, useState} from "react";
import {useAuth} from "../../../services/use-auth";
import {getDocs, collection} from "firebase/firestore";
import StyledButton from "../../../assets/buttons";
import {
  CourseWrapper,
  Spacer,
  CourseName,
  CourseElement,
  CoursesWrapper,
} from "./schedule_course_styles";

const SelectACourse = (props) => {
  const [courseObjects, setCourseObjects] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState(undefined);
  const [searchCourses, setSearchCourses] = useState("");

  const authHook = useAuth();

  useEffect(() => {
    if (props.institution.docId) {
      let courses = [];
      const fetchInstitutionData = async () => {
        const coursesSnap = await getDocs(
          collection(
            authHook.db,
            "institutions",
            props.institution.docId,
            "courses"
          )
        );
        coursesSnap.forEach((doc) => {
          let course = doc.data();
          course["courseID"] = doc.id;
          const areaFormated =
            course.area.charAt(0) + course.area.substring(1).toLowerCase();
          course["label"] = areaFormated
            .concat(" ")
            .concat(course.courseNumber);
          courses.push(course);
        });
        setCourseObjects(courses);
      };
      fetchInstitutionData();
    }
  }, [props.institution.docId, authHook.db]);

  useEffect(() => {
    if (searchCourses.length > 0) {
      let filteredTemp = courseObjects.filter((course) =>
        course.area.toUpperCase().includes(searchCourses)
      );
      setFilteredCourses(filteredTemp);
    } else {
      setFilteredCourses(courseObjects);
    }
  }, [courseObjects, props.courseObjects, searchCourses]);

  return (
    <React.Fragment>
      {filteredCourses && filteredCourses.length > 0 && (
        <CoursesWrapper>
          {filteredCourses.map((course) => (
            <CourseWrapper key={course.area.concat(course.courseNumber)}>
              <StyledButton
                bright
                fontSize={"14px"}
                height={"35px"}
                width={"150px"}
                small
                callback={() => props.setSelectedCourse(course)}>
                Start Flow
              </StyledButton>
              <Spacer />
              <CourseName>{course.name}</CourseName>
              <CourseElement>{course.area}</CourseElement>
              <CourseElement>{course.courseNumber}</CourseElement>
              <CourseElement>{course.lheTotal} LHE</CourseElement>
            </CourseWrapper>
          ))}
        </CoursesWrapper>
      )}
    </React.Fragment>
  );
};

export default SelectACourse;
