import React, {useState, useEffect} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {
  setDoc,
  doc,
  collection,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {useAuth} from "../../../services/use-auth";
import StyledButton from "../../../assets/buttons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Text,
  ComponentInnerWrapper,
  ComponentOutterWrapper,
  FooterWrapper,
  HeaderWrapper,
  Table,
  TableHeader,
  LoadingLogoComp,
  TitleText,
  ModalBox,
  ModalInner,
  LargeTitle,
} from "./modify_resources_css";
import {ConfirmAction} from "../../../assets/controls";
import PostAdd from "@mui/icons-material/PostAdd";

const AddOrChangeCourse = (props) => {
  const [values, setValues] = useState({
    name: "",
    area: "",
    courseNumber: "",
    hoursLab: 0,
    hoursLecture: "",
    hasLab: false,
    online: false,
    mustScheduleInLab: false,
  });
  const [entryErrors, setEntryErrors] = useState({
    name: false,
    area: false,
    courseNumber: false,
    hoursLab: false,
    hoursLecture: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    name: false,
    area: false,
    courseNumber: false,
    hoursLab: false,
    hoursLecture: false,
  });

  useEffect(() => {
    if (props.dataIn && props.dataIn.id.length > 0 && values.area === "") {
      setValues({
        name: props.dataIn.name,
        area: props.dataIn.area,
        courseNumber: props.dataIn.courseNumber,
        hoursLab: props.dataIn.hoursLab,
        hoursLecture: props.dataIn.lheLecture,
        hasLab: props.dataIn.hoursLab > 0,
        online: props.dataIn.online,
        mustScheduleInLab: props.dataIn.mustScheduleInLab || false,
      });
    }
  }, [props.dataIn]);

  const handleChange = (event) => {
    console.log(event.target.checked);
    if (
      event.target.name === "hasLab" ||
      event.target.name === "mustScheduleInLab"
    ) {
      if (event.target.checked) {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.checked,
          hoursLab: "",
        }));
      } else {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.checked,
          hoursLab: 0,
        }));
      }
    } else if (event.target.name === "area") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.toUpperCase(),
      }));
    } else if (event.target.name === "online") {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.checked,
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const validateName = () => {
    if (values.name === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, name: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: "You must enter a course name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, name: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: null,
      }));
    }
  };

  const validateArea = () => {
    if (values.area === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, area: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        area: "You must enter a course area",
      }));
    } else {
      if (values.area.length !== 4) {
        setEntryErrors((entryErrors) => ({...entryErrors, area: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          area: "It should contain 4 letters",
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, area: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          area: null,
        }));
      }
    }
  };

  const validateCourseNumber = () => {
    if (values.name === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, name: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: "You must enter a course number",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, name: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: null,
      }));
    }
  };

  const validateHoursLecture = () => {
    if (values.hoursLecture === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, hoursLecture: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        hoursLecture: "You must enter lecture hours",
      }));
    } else {
      if (isNaN(parseFloat(values.hoursLecture))) {
        setEntryErrors((entryErrors) => ({...entryErrors, hoursLecture: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          hoursLecture: "Units/Hours must be a number",
        }));
      } else {
        if (parseFloat(values.hoursLecture) !== parseInt(values.hoursLecture)) {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            hoursLecture: true,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            hoursLecture: "Units/Hours must be a whole number",
          }));
        } else {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            hoursLecture: false,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            hoursLecture: null,
          }));
        }
      }
    }
  };

  const validateHoursLab = () => {
    if (values.hoursLab === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, hoursLab: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        hoursLab:
          "You've selected attached lab, so you must enter lab hours/unts",
      }));
    } else {
      if (isNaN(parseFloat(values.hoursLab))) {
        setEntryErrors((entryErrors) => ({...entryErrors, hoursLab: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          hoursLab: "Units/Hours must be a number",
        }));
      } else {
        if (parseFloat(values.hoursLab) !== parseInt(values.hoursLab)) {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            hoursLab: true,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            hoursLab: "Units/Hours must be a whole number (integer)",
          }));
        } else {
          setEntryErrors((entryErrors) => ({
            ...entryErrors,
            hoursLab: false,
          }));
          setEntryFeedback((entryFeedback) => ({
            ...entryFeedback,
            hoursLab: null,
          }));
        }
      }
    }
  };

  const invalid =
    entryErrors.name ||
    entryErrors.area ||
    entryErrors.hoursLecture ||
    entryErrors.courseNumber ||
    (values.hasLab && entryErrors.hoursLab);

  const addCourse = async (values) => {
    console.log("Adding: ", values);
    props.closeModal();
    const docRef = doc(
      props.authHook.db,
      "institutions",
      props.institution.docId,
      "courses",
      `${values.area}_${values.courseNumber}`
    );
    const lheLab = Number(
      (props.institution.labRate * values.hoursLab).toFixed(2)
    );
    const lheLecture = parseInt(values.hoursLecture);

    const courseData = {
      area: values.area,
      courseNumber: values.courseNumber,
      hoursLab: parseInt(values.hoursLab),
      name: values.name,
      online: values.online,
      lheLecture: lheLecture,
      lheLab: lheLab,
      lheTotal: lheLab + lheLecture,
      mustScheduleInLab: values.mustScheduleInLab,
    };
    await setDoc(docRef, courseData);
  };

  return (
    <React.Fragment>
      <TitleText> Add or Edit Courses</TitleText>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Course Name"
          name="name"
          variant="outlined"
          color="secondary"
          value={values["name"]}
          onChange={handleChange}
          onBlur={() => validateName()}
          error={entryErrors.name}
          helperText={entryFeedback.name}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Area (Dept) of Course"
          name="area"
          variant="outlined"
          color="secondary"
          value={values["area"]}
          onChange={handleChange}
          onBlur={() => validateArea()}
          error={entryErrors.area}
          helperText={
            props.dataIn
              ? "The area cannot be chanted for an existing class"
              : entryFeedback.area
          }
          disabled={props.dataIn}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Number Code of Course"
          name="courseNumber"
          variant="outlined"
          color="secondary"
          value={values["courseNumber"]}
          onChange={handleChange}
          onBlur={() => validateCourseNumber()}
          error={entryErrors.courseNumber}
          helperText={
            props.dataIn
              ? "The area cannot be chanted for an existing class"
              : entryFeedback.courseNumber
          }
          disabled={props.dataIn}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Lecture Hours per Week"
          name="hoursLecture"
          variant="outlined"
          color="secondary"
          value={values["hoursLecture"]}
          onChange={handleChange}
          onBlur={() => validateHoursLecture()}
          error={entryErrors.hoursLecture}
          helperText={entryFeedback.hoursLecture}
        />
        <FormControlLabel
          control={
            <Switch
              name={"hasLab"}
              checked={values["hasLab"]}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Attached Lab"
        />
        {values.hasLab && (
          <React.Fragment>
            <TextField
              sx={{width: "350px", fontSize: "22px"}}
              label="Lab Hours per Week"
              name="hoursLab"
              variant="outlined"
              color="secondary"
              value={values["hoursLab"]}
              onChange={handleChange}
              onBlur={() => validateHoursLab()}
              error={entryErrors.hoursLab}
              helperText={entryFeedback.hoursLab}
            />
            <FormControlLabel
              control={
                <Switch
                  name={"mustScheduleInLab"}
                  checked={values["mustScheduleInLab"]}
                  onChange={handleChange}
                  color="secondary"
                />
              }
              label="Lab Room Area Specific"
            />
          </React.Fragment>
        )}
        <FormControlLabel
          control={
            <Switch
              name={"online"}
              checked={values["online"]}
              onChange={handleChange}
              color="secondary"
            />
          }
          label="Online Approved"
        />
        <StyledButton
          disabled={invalid}
          primary
          callback={() => addCourse(values)}
          width="250px"
          fontSize="26px">
          {props.buttonText} <PostAdd />
        </StyledButton>
      </Box>
    </React.Fragment>
  );
};

const Courses = (props) => {
  const [courses, setCourses] = useState([]);
  const [courseDataToEdit, setCourseDataToEdit] = useState({});
  const [CourseToDelete, setCourseToDelete] = useState(undefined);
  const [numberOfCoursesToDisplay, setNumberOfCoursesToDisplay] = useState(10);
  const [refineCourses, setRefineCourses] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);

  const [openAddCourse, setOpenAddCourse] = useState(false);
  const handleOpenAddCourse = () => setOpenAddCourse(true);
  const handleCloseAddCourse = () => setOpenAddCourse(false);

  const [openEditCourse, setOpenEditCourse] = useState(false);
  const handleOpenEditCourse = () => setOpenEditCourse(true);
  const handleCloseEditCourse = () => {
    setCourseDataToEdit({});
    setOpenEditCourse(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const authHook = useAuth();

  useEffect(() => {
    if (props.institution.docId) {
      const collectionRef = collection(
        authHook.db,
        "institutions",
        props.institution.docId,
        "courses"
      );

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const newCourses = [];
        const removedCourses = [];
        const changedCourses = [];
        snapshot.docChanges().forEach((change) => {
          console.log(change);
          if (change.type === "added") {
            console.log("New course: ", change.doc.data());
            if (!courses.some((course) => change.doc.id === course.id)) {
              const newCourse = change.doc.data();
              newCourse["id"] = change.doc.id;
              newCourses.push(newCourse);
            }
          }
          if (change.type === "modified") {
            console.log("Modified course: ", change.doc.data());
            const changedCourse = change.doc.data();
            changedCourse["id"] = change.doc.id;
            changedCourses.push(changedCourse);
          }
          if (change.type === "removed") {
            console.log("Removed course: ", change.doc.id);
            removedCourses.push(change.doc.id);
          }
        });
        setCourses((courses) => {
          courses.filter((course) =>
            removedCourses.some((removedItem) => removedItem !== course.id)
          );
          return [
            ...courses.filter(
              (course) =>
                (removedCourses.length === 0 && changedCourses.length === 0) ||
                removedCourses.some(
                  (removedItem) => removedItem !== course.id
                ) ||
                changedCourses.some(
                  (alteredItem) => alteredItem.id !== course.id
                )
            ),
            ...newCourses,
            ...changedCourses,
          ];
        });
      });

      return () => {
        unsubscribe();
      };
    }
  }, [authHook.db, props.institution.docId]);

  useEffect(() => {
    if (refineCourses) {
      let filteredTemp = courses.filter((course) =>
        course.name.toLowerCase().includes(refineCourses.toLowerCase())
      );
      setFilteredCourses(
        filteredTemp.sort((a, b) => (a.area > b.area ? 1 : -1))
      );
    } else {
      setFilteredCourses(
        courses.sort((a, b) => {
          if (a.area !== b.area) {
            return a.area > b.area ? 1 : -1;
          } else {
            return a.courseNumber > b.courseNumber ? 1 : -1;
          }
        })
      );
    }
  }, [courses, refineCourses]);

  const removeCourse = (dataIn) => {
    console.log(`Removing course: ${dataIn.id}`);
    deleteDoc(
      doc(
        authHook.db,
        "institutions",
        props.institution.docId,
        "courses",
        dataIn.id
      )
    );
  };

  const handleChange = (event) => {
    setRefineCourses(event.target.value);
  };

  const theme = createTheme({
    palette: {mode: "dark", background: "white"},
  });

  return (
    <ComponentOutterWrapper>
      <ComponentInnerWrapper>
        <LargeTitle>Courses</LargeTitle>
        <HeaderWrapper>
          <ThemeProvider theme={theme}>
            <StyledButton
              bright
              callback={() => handleOpenAddCourse()}
              fontSize={"1.25rem"}
              height={"2.8rem"}
              width={"14rem"}>
              Add Course <PostAdd style={{paddingLeft: "12px"}} />
            </StyledButton>
            <Box component="form" sx={{pl: 5}} noValidate autoComplete="off">
              <TextField
                sx={{width: "250px", fontSize: "22px"}}
                label="Search Courses"
                variant="outlined"
                color="secondary"
                value={refineCourses}
                onChange={handleChange}
              />
            </Box>
          </ThemeProvider>
        </HeaderWrapper>
        <Table>
          <TableHeader>Course Code</TableHeader>
          <TableHeader>Course Name</TableHeader>
          <TableHeader>Load Units</TableHeader>
          <TableHeader></TableHeader>
          {filteredCourses.length > 0 &&
            filteredCourses.map(
              (course, index) =>
                index <= numberOfCoursesToDisplay && (
                  <React.Fragment key={course.area.concat(course.courseNumber)}>
                    <div>
                      <Text>
                        {course.area} {course.courseNumber}
                      </Text>
                    </div>
                    <div>
                      <Text>{course.name}</Text>
                    </div>
                    <div>
                      <Text>{course.lheTotal}</Text>
                    </div>
                    <div>
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          paddingRight: "2vw",
                          color: "white",
                        }}
                        onClick={() => {
                          console.log(`Edit ${course.email}`);
                          setCourseDataToEdit(course);
                          handleOpenEditCourse();
                        }}
                      />
                      <DeleteForeverIcon
                        style={{cursor: "pointer", color: "white"}}
                        onClick={() => {
                          setCourseToDelete({
                            id: `${course.area}_${course.courseNumber}`,
                            label: course.name,
                          });
                          handleOpenDelete();
                        }}
                      />
                    </div>
                  </React.Fragment>
                )
            )}
        </Table>
        {courses.length === 0 && <LoadingLogoComp />}
        <Modal open={openAddCourse} onClose={handleCloseAddCourse}>
          <ModalBox>
            <ModalInner>
              <AddOrChangeCourse
                buttonText={"Add Course"}
                authHook={authHook}
                closeModal={handleCloseAddCourse}
                institution={props.institution}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <Modal open={openEditCourse} onClose={handleCloseEditCourse}>
          <ModalBox>
            <ModalInner>
              <AddOrChangeCourse
                buttonText={"Edit Course"}
                authHook={authHook}
                closeModal={handleCloseEditCourse}
                institution={props.institution}
                dataIn={courseDataToEdit}
              />
            </ModalInner>
          </ModalBox>
        </Modal>
        <ConfirmAction
          dataForAction={CourseToDelete}
          callback={(data) => removeCourse(data)}
          openState={openDelete}
          handleCloseState={() => handleCloseDelete()}>
          Delete
        </ConfirmAction>
        <FooterWrapper>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"17rem"}
            callback={() =>
              setNumberOfCoursesToDisplay(
                (numberOfInstructorsToDisplay) =>
                  numberOfInstructorsToDisplay + 20
              )
            }>
            Show More
          </StyledButton>
          <StyledButton
            bright
            fontSize={"1.25rem"}
            height={"2.3rem"}
            width={"17rem"}
            callback={() => props.setResourceComp("none")}>
            Done With Courses
          </StyledButton>
        </FooterWrapper>
      </ComponentInnerWrapper>
    </ComponentOutterWrapper>
  );
};

export default Courses;
