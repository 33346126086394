import React, {useState} from "react";
import {
  WrapperVert,
  WrapperHoriz,
  Descriptor,
  FooterWrapper,
  Directions,
} from "./signup_flow_css";
import StyledButton from "../../assets/buttons";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export const SelectAdmin = (props) => {
  const [values, setValues] = useState({email: "", emailConfirm: ""});
  const [entryErrors, setEntryErrors] = useState({
    email: false,
    emailConfirm: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    email: null,
    emailConfirm: null,
  });

  const handleChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const validateEmailConfirm = () => {
    if (values.emailConfirm === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, emailConfirm: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        emailConfirm: "You must enter a email",
      }));
    } else {
      if (values.email !== values.emailConfirm) {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          emailConfirm: true,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          emailConfirm: "Your Email Addresses Don't Match",
        }));
      } else {
        setEntryErrors((entryErrors) => ({
          ...entryErrors,
          emailConfirm: false,
        }));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          emailConfirm: null,
        }));
      }
    }
  };

  const validateEmail = () => {
    if (values.email === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        email: "You must enter a email",
      }));
    } else {
      const containsAtAndPeriod =
        values.email.includes("@") && values.email.includes(".");
      if (!containsAtAndPeriod) {
        setEntryErrors((entryErrors) => ({...entryErrors, email: true}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: "You must enter a valid email address",
        }));
      } else {
        setEntryErrors((entryErrors) => ({...entryErrors, email: false}));
        setEntryFeedback((entryFeedback) => ({
          ...entryFeedback,
          email: null,
        }));
        if (values.emailConfirm.length > 0) {
          validateEmailConfirm();
        }
      }
    }
  };

  const clickNext = () => {
    props.setConfirmedValues((confirmedValues) => ({
      ...confirmedValues,
      adminEmail: values.email,
    }));
    props.setStatus("Confirm");
  };

  return (
    <WrapperVert>
      <WrapperHoriz>
        <Descriptor>Set Your Admin</Descriptor>
        <Directions>
          Remember, this user will be able to add faculty, courses, rooms and
          schedule classes. As "owner" you will still maintain the ability to do
          these things, but this would generally be someone you can delegate
          some of these tasks to.
        </Directions>
      </WrapperHoriz>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Email Address"
          name="email"
          variant="outlined"
          color="secondary"
          value={values["email"]}
          onChange={handleChange}
          onBlur={() => validateEmail()}
          error={entryErrors.email}
          helperText={entryFeedback.email}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Email Confirm"
          name="emailConfirm"
          variant="outlined"
          color="secondary"
          value={values["emailConfirm"]}
          onChange={handleChange}
          onBlur={() => validateEmailConfirm()}
          error={entryErrors.emailConfirm}
          helperText={entryFeedback.emailConfirm}
        />
      </Box>
      <FooterWrapper>
        <StyledButton
          bright
          width={"300px"}
          fontSize={"24px"}
          callback={clickNext}>
          Set Our Admin
        </StyledButton>
      </FooterWrapper>
    </WrapperVert>
  );
};
