import styled from "@emotion/styled";
import Box from "@mui/material/Box";

import {ReactComponent as LoadingLogo} from "../../../assets/logos/animated-logo-light.svg";

export const ComponentInnerWrapper = styled.div`
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 5vh;
  padding-top: 2vh;
  border-radius: 8px;
  background-image: linear-gradient(to right, #1e242b, black);
`;

export const ComponentOutterWrapper = styled.div`
  margin-right: 10vw;
  margin-left: 10vw;
  background-color: #cccccc;

  //Code to make a border gradient
  border: double 6px transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    radial-gradient(
      circle at top left,
      #b22418,
      #f19e38,
      #94cd3f,
      #57aff6,
      #d02c85,
      #56193f
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 2vh;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
`;

export const LargeTitle = styled.p`
  font-size: 32px;
  font-family: "MontserratBold";
  color: white;
  text-align: center;
`;

export const TitleText = styled.p`
  font-size: 26px;
  font-family: "MontserratBold";
`;

export const TableHeader = styled.p`
  font-size: 18px;
  font-family: "MontserratBold";
  color: white;
  margin-right: 4vw;
`;

export const Table = styled.div`
  display: grid;
  width: 68vw;
  padding-left: 2vw;
  grid-auto-flow: row;
  align-items: center;
  grid-template-columns: repeat(4, auto);
`;

export const TableHeaderDiv = styled.div`
  border-bottom: 2px solid #424343;
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 250px;
  padding-bottom: 250px;
`;

export const LoadingLogoComp = () => {
  return (
    <LoadingWrapper>
      <LoadingLogo style={{width: "20vw"}} />
    </LoadingWrapper>
  );
};

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;
