import React, {useState} from "react";
import styled from "@emotion/styled";
import StyledButton from "../../assets/buttons";

const InvisInput = styled.input`
  display: none;
`;

const StyledText = styled.p`
  font-size: 22px;
  font-family: "MontserratMed";
`;

const StyledTableItem = styled.td`
  font-size: 20px;
  font-family: "MontserratMed";
  padding-right: 20px;
  padding-top: 4px;
  text-align: center;
`;

const StyledHeaderText = styled.th`
  font-size: 20px;
  font-family: "MontserratBold";
  padding-right: 30px;
  text-align: center;
`;

const StyledTableRow = styled.tr`
  padding-top: 4px;
  padding-right: 10px;
`;

const BrowseLabel = styled.label`
  padding: 4px 6px;
  font-size: 22px;
  font-family: "MontserratMed";
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #1e242b, black);
  border-radius: 6px;
  width: 100px;
  height: 35px;
  cursor: pointer;

  &:hover {
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(to right, #469dd7, #70c8b8);
  }
`;

const ImportButton = styled.button`
  padding: 4px 6px;
  font-size: 22px;
  font-family: "MontserratMed";
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #1e242b, black);
  border-radius: 6px;
  width: 100px;
  height: 35px;
  cursor: pointer;

  &:hover {
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(to right, #ee3189, #f36d21);
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CSVLoader = (props) => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    setCsvHeader(string.slice(0, string.indexOf("\n")).split(","));
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((row) => {
      const rowObj = {};
      const rowArr = row.split(",");
      if(rowArr[3] === "" || rowArr[3] === undefined) {
        return {};
      }
      console.log(rowArr);
      for (let x = 0; x < props.headers.length; x++) {
        const key = props.headers[x];
        if (rowArr[x]) {
          rowObj[key] = rowArr[x].split("\r")[0];
        } else {
          rowObj[key] = "";
        }
      }
      return rowObj;
    });

    const fullArr = array.filter((obj) => Object.keys(obj).length !== 0);

    console.log(fullArr);
    setArray(fullArr);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = (event) => {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div>
      {array.length === 0 && (
        <form>
          <BrowseLabel htmlFor="file-upload" className="custom-file-upload">
            {file ? "Change File" : "Browse for File"}
          </BrowseLabel>
          {file && <StyledText>{file.name}</StyledText>}
          <InvisInput
            type={"file"}
            accept={".csv"}
            id={"file-upload"}
            onChange={handleOnChange}
          />
          {file && (
            <ImportButton
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              primary
              fontSize={"20px"}
            >
              Import CSV
            </ImportButton>
          )}
        </form>
      )}
      <br />
      <table>
        <thead>
          <StyledTableRow key={"header"}>
            {headerKeys.map((key) => (
              <StyledHeaderText>{key}</StyledHeaderText>
            ))}
          </StyledTableRow>
        </thead>

        <tbody>
          {array.map((item) => (
            <StyledTableRow key={item.id}>
              {Object.values(item).map((val) => (
                <StyledTableItem>{val}</StyledTableItem>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      </table>
      {array.length > 0 && (
        <FooterWrapper>
          <StyledButton
            secondary
            fontSize={"24px"}
            margin={"8px"}
            callback={() => {
              setArray([]);
              setFile();
            }}
          >
            Change File
          </StyledButton>
          <StyledButton
            primary
            fontSize={"24px"}
            margin={"8px"}
            callback={() => props.setDataFunc(array)}
          >
            Confirm {props.confirmButtonText}
          </StyledButton>
        </FooterWrapper>
      )}
    </div>
  );
};

export default CSVLoader;
