import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import * as ROUTES from "../constants/routes";

const NoMatch = (props) => {
  const redirect = useNavigate();
  useEffect(() => {
    if (!props.stillLoading) {
      if (props.authUser !== null) {
        console.log("Should redirect to HOME");
        redirect(ROUTES.HOME);
      } else {
        console.log("Should redirect to SIGN_IN");
        redirect(ROUTES.SIGN_IN);
      }
    }
  }, [props.stillLoading, props.authUser]);

  return (
    <React.Fragment>
      <h1>Redirecting to Login or Home</h1>
    </React.Fragment>
  );
};

export default NoMatch;
