import React, {useState} from "react";
import {useAuth} from "../../services/use-auth";
import styled from "@emotion/styled";

import {doc, setDoc} from "firebase/firestore";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import StyledButton from "../../assets/buttons";

const NewInstWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: "MontserratMed";
`;

const CreateInst = (props) => {
  const authHook = useAuth();

  const [createInstData, setCreateInstData] = useState({
    name: "",
    labFraction: "",
    otherEmailExtensions: "",
  });
  const [entryErrors, setEntryErrors] = useState({
    name: false,
    labFraction: false,
    otherEmailExtensions: false,
  });
  const [entryFeedback, setEntryFeedback] = useState({
    name: null,
    labFraction: null,
    otherEmailExtensions: null,
  });

  const handleChange = (event) => {
    setCreateInstData((createInstData) => ({
      ...createInstData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateInstName = () => {
    if (createInstData.name === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, name: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: "You must enter an institution name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, name: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        name: null,
      }));
    }
  };

  const validateLabFraction = () => {
    if (createInstData.labFraction === "") {
      setEntryErrors((entryErrors) => ({...entryErrors, labFraction: true}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        labFraction: "You must enter an institution name",
      }));
    } else {
      setEntryErrors((entryErrors) => ({...entryErrors, labFraction: false}));
      setEntryFeedback((entryFeedback) => ({
        ...entryFeedback,
        labFraction: null,
      }));
    }
  };

  const validateOtherExtensions = () => {
    //None here, need to re-write since these may be blank
  };

  const submitRegisterInst = () => {
    const newInst = doc(
      authHook.db,
      "users",
      props.authUser.uid,
      "institutionsRequested",
      createInstData.name.replace(/\s+/g, "_").toUpperCase()
    );
    const emailExtOfUser = props.userData.email.split("@")[1];
    let otherEmailExtensionsEntered;
    if (createInstData.otherEmailExtensions !== "") {
      otherEmailExtensionsEntered = createInstData.otherEmailExtensions
        .replace(/\s+/g, "")
        .split(",");
    } else {
      otherEmailExtensionsEntered = undefined;
    }
    console.log(otherEmailExtensionsEntered);
    const emailExtensions = [emailExtOfUser];
    console.log(emailExtensions);
    if (otherEmailExtensionsEntered && otherEmailExtensionsEntered.length > 0) {
      otherEmailExtensionsEntered.forEach((ext) => emailExtensions.push(ext));
    }
    const labPercentageParsed = parseFloat(createInstData.labFraction / 100);
    console.log(emailExtensions);
    setDoc(
      newInst,
      {
        name: createInstData.name,
        ownerEmail: props.userData.email,
        emplEmailExts: emailExtensions,
        labPercentageLHE: labPercentageParsed,
      },
      {merge: true}
    );
  };

  const invalid =
    createInstData.name === "" ||
    createInstData.labFraction === "" ||
    createInstData.labFraction === "" ||
    entryErrors.name ||
    entryErrors.labFraction ||
    entryErrors.otherEmailExtensions;

  return (
    <NewInstWrapper>
      <Title>Register New Institution</Title>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          pb: 3,
          pl: 2,
          pr: 2,
        }}
        noValidate
        autoComplete="off">
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Name of Institution"
          name="name"
          variant="outlined"
          color="secondary"
          value={createInstData["name"]}
          onChange={handleChange}
          onBlur={() => validateInstName()}
          error={entryErrors.name}
          helperText={entryFeedback.name}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Lab Percentage of LHE"
          name="labFraction"
          variant="outlined"
          color="secondary"
          value={createInstData["labFraction"]}
          onChange={handleChange}
          onBlur={() => validateLabFraction()}
          error={entryErrors.labFraction}
          helperText={entryFeedback.labFraction}
        />
        <TextField
          sx={{width: "350px", fontSize: "22px"}}
          label="Email Extensions Used Other Than Yours"
          name="otherExtensions"
          variant="outlined"
          color="secondary"
          value={createInstData["otherExtensions"]}
          onChange={handleChange}
          onBlur={() => validateOtherExtensions()}
          error={entryErrors.otherExtensions}
          helperText={entryFeedback.otherExtensions}
        />
      </Box>
      <StyledButton
        disabled={invalid}
        bright
        callback={submitRegisterInst}
        width="250px"
        fontSize="26px">
        Create!
      </StyledButton>
    </NewInstWrapper>
  );
};

export default CreateInst;
