import React, { useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

//Adapted from : https://usehooks.com/useAuth/

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: "course-schedge.firebaseapp.com",
//   projectId: "course-schedge",
//   storageBucket: "course-schedge.appspot.com",
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDING_ID,
//   appId: process.env.REACT_APP_FIREBASE_APPLICATION_ID,
// };

const configDirect = {
  apiKey: "AIzaSyB9yTnDzmPgAxd3F-iHMJXgZ4JSVVXpok4",
  authDomain: "course-schedge.firebaseapp.com",
  projectId: "course-schedge",
  storageBucket: "course-schedge.appspot.com",
  messagingSenderId: "702439641101",
  appId: "1:702439641101:web:63498785ecedbdf5fe4544",
};

const authContext = createContext();

//Wraps the app and makes auth object available to any child calling useAuth()
export const ProvideAuth = ({ children }) => {
  //This is running twice for some reason
  const authHook = useProvideAuth();

  return (
    <authContext.Provider value={authHook}>{children}</authContext.Provider>
  );
};

//Hook for child compoenets to get the auth object
//and re-render upon change.
export const useAuth = () => {
  return useContext(authContext);
};

//Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  console.log("Triggering ProvideAuth Hook to run");

  const app = initializeApp(configDirect);
  const db = getFirestore(app);
  const auth = getAuth(app);

  if (window.location.href.split("/")[2].split(":")[0] === "localhost") {
    console.log(`Connecting emulators since the address is localhost`);
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://localhost:9099");
  }

  return {
    app,
    db,
    auth,
  };
};
