import PropTypes from "prop-types";
import styled from "@emotion/styled";
import SelectUnstyled, {selectUnstyledClasses} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {optionUnstyledClasses} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import Box from "@mui/material/Box";

import {ReactComponent as LoadingLogo} from "../../../assets/logos/animated-logo-light.svg";

export const ComponentInnerWrapper = styled.div`
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 5vh;
  padding-top: 2vh;
  border-radius: 8px;
  background-image: linear-gradient(to right, #1e242b, black);
`;

export const ComponentOutterWrapper = styled.div`
  margin-right: 10vw;
  margin-left: 10vw;
  background-color: #cccccc;

  //Code to make a border gradient
  border: double 6px transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    radial-gradient(
      circle at top left,
      #b22418,
      #f19e38,
      #94cd3f,
      #57aff6,
      #d02c85,
      #56193f
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 2vh;
`;

export const LargeTitle = styled.p`
  font-size: 32px;
  font-family: "MontserratBold";
  color: white;
  text-align: center;
`;

export const MedDarkTitle = styled.p`
  font-size: 24px;
  font-family: "MontserratBold";
  text-align: center;
`;

export const Text = styled.p`
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
`;

export const SmallText = styled.p`
  font-size: 16px;
  font-family: "MontserratMed";
  color: white;
  padding-right: 10px;
`;

const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 250px;
  padding-bottom: 250px;
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: white;
  padding: 5px;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 25px;
`;

export const LoadingLogoComp = () => {
  return (
    <LoadingWrapper>
      <LoadingLogo style={{width: "20vw"}} />
    </LoadingWrapper>
  );
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledButtonItem = styled("button")(
  ({theme}) => `
  font-family: MontserratMed;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 250px;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({theme}) => `
  font-family: MontserratMed;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `
);

export const StyledOption = styled(OptionUnstyled)(
  ({theme}) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

export const Label = styled("label")(
  ({theme}) => `
  font-family: MontserratMed;
  font-size: 0.85rem;
  display: block;
  margin-bottom: 4px;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[700]};
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = (props) => {
  const slots = {
    root: StyledButtonItem,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} slots={slots} />;
};

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

export {CustomSelect};

export const CourseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CourseName = styled.p`
  width: 350px;
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
  padding-left: 25px;
`;

export const CourseElement = styled.p`
  width: 85px;
  font-size: 18px;
  font-family: "MontserratMed";
  color: white;
`;

export const Spacer = styled.div`
  width: 15px;
`;

export const CoursesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
