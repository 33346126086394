export const compiler = ({
  defaultWeek,
  instructorsAvailability,
  classroomAvailability,
}) => {
  //Check resources
  let availabilityCompiled = [];
  defaultWeek.forEach((day) => {
    //Resources available all day?
    const oneInstructorAvailAllDay = instructorsAvailability.some(
      (instructor) => {
        const thisDay = instructor.daysAvailability.filter(
          (daysAvail) => daysAvail.day === day.day
        );
        return thisDay[0].availableAllDay;
      }
    );
    const oneRoomAvailAllDay = classroomAvailability.some((classroom) => {
      const thisDay = classroom.daysAvailability.filter(
        (daysAvail) => daysAvail.day === day.day
      );
      return thisDay[0].availableAllDay;
    });
    const dayFullyAvailable = oneInstructorAvailAllDay && oneRoomAvailAllDay;

    //Need to check if the lecture timespan fits in consecutive hours for this instructor
    // console.log(`Spans`);
    // console.log(lectureSpansSelected);

    //The entire day isn't free, so check for and create spans
    if (!dayFullyAvailable) {
      //Check each hour
      const hoursAvailCompiled = [];
      day.hours.forEach((hour) => {
        const oneInstructorHourFullyAvail = instructorsAvailability.some(
          (instructor) => {
            const thisDay = instructor.daysAvailability.filter(
              (daysAvail) => daysAvail.day === day.day
            )[0];

            if (thisDay.availableAllDay) {
              //This resource is available all day, so return true
              return true;
            }
            const intervalIfHoursFit = thisDay.availabilityIntervals.filter(
              (interval) =>
                (interval.initialHour < hour.hour ||
                  (interval.initialHour === defaultWeek[0].hours[0].hour &&
                    interval.initialMinute === 0)) &&
                interval.finalHour > hour.hour
            );
            if (intervalIfHoursFit.length > 0) {
              //This hour fully fits within the span of hours, no need to look at minutes
              return true;
            }
            //If we have reached this point, we need to check minute intervals and combine them, so false for fully avail
            return false;
          }
        );
        const oneRoomHourFullyAvail = classroomAvailability.some(
          (classroom) => {
            const thisDay = classroom.daysAvailability.filter(
              (daysAvail) => daysAvail.day === day.day
            )[0];
            //This resource is available all day, so return true
            if (thisDay.availableAllDay) {
              return true;
            }
            const intervalIfHoursFit = thisDay.availabilityIntervals.filter(
              (interval) =>
                (interval.initialHour < hour.hour ||
                  (interval.initialHour === defaultWeek[0].hours[0].hour &&
                    interval.initialMinute === 0)) &&
                interval.finalHour > hour.hour
            );
            if (intervalIfHoursFit.length > 0) {
              return true;
            }
            return false;
          }
        );
        //True if there is an instructor and a room available for the full hour
        const hourFullyAvailable =
          oneInstructorHourFullyAvail && oneRoomHourFullyAvail;
        if (day.day === "Monday" && hour.hour === 7) {
          // console.log(hourFullyAvailable);
        }

        if (!hourFullyAvailable) {
          const compileOneResource = ({ availabilityArr }) => {
            //Build intervals of availability for partial by combining availability for Generic
            const thisHourIntervalTotal = {};
            const thisHourSpecific = {};
            availabilityArr.forEach((element) => {
              const thisDay = element.daysAvailability.filter(
                (daysAvail) => daysAvail.day === day.day
              )[0];

              const intervalForHour = thisDay.availabilityIntervals.filter(
                (interval) =>
                  interval.initialHour <= hour.hour &&
                  interval.finalHour >= hour.hour
              )[0];
              if (intervalForHour !== undefined) {
                //There is an interval that fits, so there is some availability
                // if (day.day === "Monday" && hour.hour === 10 && intervalForHour) {
                //   // console.log(intervalForHour);
                // }
                let finalMinute, initialMinute;
                if (
                  intervalForHour.initialHour < hour.hour ||
                  (intervalForHour.initialHour === hour.hour &&
                    intervalForHour.initialMinute === 0)
                ) {
                  initialMinute = 0;
                } else {
                  initialMinute = intervalForHour.initialMinute;
                }
                if (
                  intervalForHour.finalHour > hour.hour ||
                  (intervalForHour.finalHour === hour.hour &&
                    intervalForHour.finalMinute === 60)
                ) {
                  finalMinute = 60;
                } else {
                  finalMinute = intervalForHour.finalMinute;
                }

                thisHourSpecific[`anyAvail`] = true;
                thisHourSpecific[`initialMinute`] = initialMinute;
                thisHourSpecific[`finalMinute`] = finalMinute;
                // if (day.day === "Monday" && hour.hour === 10 && intervalForHour) {
                //   console.log(thisHourSpecific);
                // }
              } else {
                //Hour not available, push false to hourAvail
                thisHourSpecific[`anyAvail`] = false;
              }

              //Combine with previous hour intervals, then push hour interval to hourAvail
              if (
                !thisHourSpecific.anyAvail &&
                thisHourIntervalTotal.anyAvail
              ) {
                //This has no avail, but there's alreay avail in this hour from a previous
                // console.log(`no change needed`);
              } else if (
                !thisHourSpecific.anyAvail &&
                !thisHourIntervalTotal.anyAvail
              ) {
                thisHourIntervalTotal[`anyAvail`] = false;
              } else if (
                thisHourSpecific.anyAvail &&
                !thisHourIntervalTotal.anyAvail
              ) {
                thisHourIntervalTotal[`anyAvail`] = true;
                thisHourIntervalTotal[`initialMinute`] =
                  thisHourSpecific.initialMinute;
                thisHourIntervalTotal[`finalMinute`] =
                  thisHourSpecific.finalMinute;
              } else if (
                thisHourSpecific.anyAvail &&
                thisHourIntervalTotal.anyAvail
              ) {
                if (
                  thisHourSpecific.initialMinute <
                  thisHourIntervalTotal.initialMinute
                ) {
                  thisHourIntervalTotal[`anyAvail`] = true;
                  thisHourIntervalTotal[`initialMinute`] =
                    thisHourSpecific.initialMinute;
                  thisHourIntervalTotal[`finalMinute`] =
                    thisHourSpecific.finalMinute;
                } else if (
                  thisHourSpecific.anyAvail &&
                  thisHourIntervalTotal.anyAvail
                ) {
                  if (
                    thisHourSpecific.initialMinute <
                    thisHourIntervalTotal.initialMinute
                  ) {
                    thisHourIntervalTotal[`initialMinute`] =
                      thisHourSpecific.initialMinute;
                  }
                  if (
                    thisHourSpecific.finalMinute >
                    thisHourIntervalTotal.finalMinute
                  ) {
                    thisHourIntervalTotal[`finalMinute`] =
                      thisHourSpecific.finalMinute;
                  }
                } else {
                  console.error(`Failed to analyze the minute spans correctly`);
                  console.log(
                    `Specific anyAvail: ${thisHourSpecific.anyAvail}`
                  );
                  console.log(
                    `Total anyAvail: ${thisHourIntervalTotal.anyAvail}`
                  );
                }
              }
            });

            return thisHourIntervalTotal;
          };

          let thisHourIntervalInstructor = {};
          let thisHourIntervalRoom = {};
          if (!oneInstructorHourFullyAvail) {
            thisHourIntervalInstructor = compileOneResource({
              availabilityArr: instructorsAvailability,
            });
            thisHourIntervalInstructor[`fullyAvailable`] = false;
          } else {
            //Instructor available during entire hour
            thisHourIntervalInstructor[`fullyAvailable`] = true;
          }
          if (!oneRoomHourFullyAvail) {
            thisHourIntervalRoom = compileOneResource({
              availabilityArr: classroomAvailability,
            });
            thisHourIntervalRoom[`fullyAvailable`] = false;
          } else {
            thisHourIntervalRoom[`fullyAvailable`] = true;
          }

          //Combine room and instructor
          if (
            thisHourIntervalInstructor.anyAvail &&
            thisHourIntervalRoom.anyAvail
          ) {
            let thisHourIntervalTotal = {};
            if (thisHourIntervalInstructor.fullyAvailable) {
              thisHourIntervalTotal[`initialMinute`] =
                thisHourIntervalRoom.initialMinute;
              thisHourIntervalTotal[`finalMinute`] =
                thisHourIntervalRoom.finalMinute;
            } else if (thisHourIntervalRoom.fullyAvailable) {
              thisHourIntervalTotal[`initialMinute`] =
                thisHourIntervalInstructor.initialMinute;
              thisHourIntervalTotal[`finalMinute`] =
                thisHourIntervalInstructor.finalMinute;
            } else {
              if (
                thisHourIntervalInstructor.initialMinute >=
                thisHourIntervalRoom.initialMinute
              ) {
                //First minute available is the instructor
                thisHourIntervalTotal[`initialMinute`] =
                  thisHourIntervalInstructor.initialMinute;
              } else if (
                thisHourIntervalInstructor.initialMinute <=
                thisHourIntervalRoom.initialMinute
              ) {
                thisHourIntervalTotal[`initialMinute`] =
                  thisHourIntervalRoom.initialMinute;
              } else {
                console.error(
                  `Error determining initial minute when combining instructor and room in getCompilation.js`
                );
              }
              if (
                thisHourIntervalInstructor.finalMinute >=
                thisHourIntervalRoom.finalMinute
              ) {
                //First minute available is the instructor
                thisHourIntervalTotal[`finalMinute`] =
                  thisHourIntervalRoom.finalMinute;
              } else if (
                thisHourIntervalInstructor.finalMinute <=
                thisHourIntervalRoom.finalMinute
              ) {
                thisHourIntervalTotal[`finalMinute`] =
                  thisHourIntervalInstructor.finalMinute;
              } else {
                console.error(
                  `Error determining final minute when combining instructor and room in getCompilation.js`
                );
              }
            }

            hoursAvailCompiled.push({
              fullyAvailable: false,
              hour: hour.hour,
              anyAvail: false,
            });
          } else {
            hoursAvailCompiled.push({
              fullyAvailable: false,
              hour: hour.hour,
            });
          }
        } else {
          //Entire hour available, push to hoursAvail
          hoursAvailCompiled.push({ fullyAvailable: true, hour: hour.hour });
        }
      });
      availabilityCompiled.push({
        day: day.day,
        availableAllDay: false,
        hours: hoursAvailCompiled,
      });
    } else {
      //Entire day available
      availabilityCompiled.push({ day: day.day, availableAllDay: true });
    }
  });
  return availabilityCompiled;
};
