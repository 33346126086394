import React from "react";
import styled from "@emotion/styled";
import Rooms from "./rooms.jsx";
import Instructors from "./instructors.jsx";
import Courses from "./courses.jsx";
import StyledButton from "../../../assets/buttons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResourceSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ModifyResource = (props) => {
  return (
    <Wrapper>
      <ResourceSelectionWrapper>
        {props.resourceComp === "none" && (
          <StyledButton
            primary
            fontSize={"1.25rem"}
            height={"2.3rem"}
            callback={() => props.setResourceComp("instructors")}>
            Edit or View Instructors
          </StyledButton>
        )}
        {props.resourceComp === "none" && (
          <StyledButton
            callback={() => props.setResourceComp("courses")}
            primary
            fontSize={"1.25rem"}
            height={"2.3rem"}>
            Edit or View Courses
          </StyledButton>
        )}
        {props.resourceComp === "none" && (
          <StyledButton
            callback={() => props.setResourceComp("rooms")}
            primary
            fontSize={"1.25rem"}
            height={"2.3rem"}>
            Edit or View Rooms
          </StyledButton>
        )}
      </ResourceSelectionWrapper>

      {props.resourceComp === "rooms" && (
        <Rooms
          institution={props.institution}
          setResourceComp={props.setResourceComp}
        />
      )}
      {props.resourceComp === "instructors" && (
        <Instructors
          institution={props.institution}
          setResourceComp={props.setResourceComp}
        />
      )}
      {props.resourceComp === "courses" && (
        <Courses
          institution={props.institution}
          setResourceComp={props.setResourceComp}
        />
      )}
    </Wrapper>
  );
};

export default ModifyResource;
